import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  getStaticConfig,
  graphql,
  injectConfig,
} from "@markham/react-services";
import { GET_HANDOVER } from "../../queries";
import CircularProgress from "@material-ui/core/CircularProgress";
import { classes } from "./styles";

class HandoverLoadJobStep extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.onConfirmIdentifier().catch(console.warn);
  }

  onConfirmIdentifier = async () => {
    const { identifier, countryCode } = this.props.match.params;

    const body = GET_HANDOVER({
      identifier,
      countryCode,
    });

    let result;

    try {
      result = await graphql(getStaticConfig().api.salesUrl, body);
    } catch (e) {
      return new Promise((resolve) =>
        this.setState(
          {
            loading: false,
            error: (e && e.message) || "Unable to load handover",
          },
          resolve
        )
      );
    }

    const { handover, resubmit } = result;

    if (!handover) {
      return new Promise((resolve) =>
        this.setState(
          {
            loading: false,
            error: `Could not find handover with number ${identifier} for ${countryCode}`,
          },
          resolve
        )
      );
    }

    if (handover.submitted && !resubmit) {
      return this.props.onChange({
        ...handover,
        identifierConfirmed: true,
        readOnly: true,
      });
    }

    if (handover.completed) {
      return this.props.onChange({
        ...handover,
        identifierConfirmed: true,
        readOnly: true,
      });
    }

    this.props.onChange({
      ...handover,
      identifierConfirmed: true,
    });
  };

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      );
    }
    if (!error) {
      return (
        <Typography>
          Unsure we got here, maybe try and refresh the page
        </Typography>
      );
    }
    return (
      <Typography>
        We received an error while trying to load the handover: {error}
      </Typography>
    );
  }
}

HandoverLoadJobStep.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default injectConfig(HandoverLoadJobStep);
