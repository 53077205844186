import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Grid, MenuItem, Select, TextField } from "@material-ui/core";

export const TYPE_MAP = {
  ARCHITECT: "Architect",
  BUILDER: "Builder",
  CONTRACTOR: "Contractor",
  CONTRACTOR_ACCOUNTS: "Contractor - Accounts",
  ENGINEER: "Engineer",
  FOREMAN: "Foreman",
  PROJECT_MANAGER: "Project Manager",
  QUALITY_SURVEYOR: "Quality Surveyor / Construction Administration",
  QUANTITY_SURVEYOR: "Quantity Surveyor",
  QUALITY_ASSURANCE: "Quality Assurance",
  SITE_QUALITY_CONTROL: "Site Quality Control",
  SITE: "Site Contact",
  SITE_ACCOUNTS: "Site - Accounts",
  OTHER: "Other",
};

export const HIDE_TYPES = ["SITE", "PROJECT_MANAGER"];

export const NAME_ONLY_TYPES = [];

export const REQUIRED_TYPES = ["ARCHITECT", "ENGINEER"];

export const TYPES = Object.keys(TYPE_MAP);

export function isContactValid(contact) {
  if (!contact) {
    return true;
  }

  try {
    if (
      typeof contact == "object" &&
      contact.type == "OTHER" &&
      !contact.name.length &&
      !contact.phoneNumber.length &&
      !contact.emailAddress.length
    ) {
      return true;
    }

    const valid =
      contact.name.length > 0 &&
      (contact.phoneNumber.length > 0 || contact.emailAddress.indexOf("@") > 0);

    return valid;
  } catch (err) {
    return false;
  }
}

export function createBlankContact(type) {
  if (!type) {
    type = "OTHER";
  }

  return {
    name: "",
    phoneNumber: "",
    emailAddress: "",
    company: "",
    type,
  };
}

export class Contact extends React.Component {
  onFieldChange(event) {
    this.props.onChange({
      ...this.props.contact,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  }

  render() {
    const {
      showSendWelcomeOption,
      welcomeEmailOptions,
      hideSiteContacts,
      disabled,
      fixedType,
      contact: {
        type,
        name,
        phoneNumber,
        emailAddress,
        company,
        sendWelcomeEmail,
      },
    } = this.props;

    return (
      <React.Fragment>
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}
        >
          <Grid item xs={12} md={3}>
            <Select
              value={type}
              style={{ marginTop: "16px", width: "98%" }}
              name="type"
              onChange={this.onFieldChange.bind(this)}
              disabled={disabled || fixedType}
            >
              {TYPES.filter(
                (givenType) =>
                  (hideSiteContacts && HIDE_TYPES.indexOf(givenType) === -1) ||
                  !hideSiteContacts ||
                  type === givenType
              )
                .sort((a, b) => (a > b ? 1 : -1))
                .map((type) => (
                  <MenuItem key={type} value={type}>
                    {TYPE_MAP[type]}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          {NAME_ONLY_TYPES.indexOf(type) < 0 ? (
            <Grid item xs={12} md={8} style={{ display: "flex" }}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  disabled={!!disabled}
                  value={name}
                  onChange={this.onFieldChange.bind(this)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="company"
                  name="company"
                  label="Company"
                  fullWidth
                  disabled={!!disabled}
                  value={company}
                  onChange={this.onFieldChange.bind(this)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone"
                  fullWidth
                  disabled={!!disabled}
                  value={phoneNumber}
                  onChange={this.onFieldChange.bind(this)}
                />
              </Grid>
              {(type == "SITE" || type == "PROJECT_MANAGER") &&
              showSendWelcomeOption ? (
                <Grid item xs={12} md={6}>
                  <TextField
                    id="emailAddress"
                    name="emailAddress"
                    label="Email Address"
                    fullWidth
                    disabled={!!disabled}
                    value={emailAddress}
                    onChange={this.onFieldChange.bind(this)}
                  />
                  {emailAddress && (
                    <Select
                      value={sendWelcomeEmail ? sendWelcomeEmail : ""}
                      style={{ marginTop: "16px", width: "98%" }}
                      name="sendWelcomeEmail"
                      onChange={this.onFieldChange.bind(this)}
                      disabled={disabled}
                    >
                      <MenuItem value="">No welcome email</MenuItem>

                      {welcomeEmailOptions &&
                        welcomeEmailOptions.map((email) => {
                          const { id, title } = email;
                          return (
                            <MenuItem key={id} value={id.toString()}>
                              {title}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <TextField
                    id="emailAddress"
                    name="emailAddress"
                    label="Email Address"
                    fullWidth
                    disabled={!!disabled}
                    value={emailAddress}
                    onChange={this.onFieldChange.bind(this)}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} md={8}>
              <TextField
                id="name"
                name="name"
                label="Name"
                fullWidth
                disabled={!!disabled}
                value={name}
                onChange={this.onFieldChange.bind(this)}
              />
            </Grid>
          )}
          {REQUIRED_TYPES.indexOf(type) < 0 &&
          typeof this.props.onDelete === "function" ? (
            <Grid item xs={12} md={1}>
              <Button variant="text" onClick={this.props.onDelete}>
                <CloseIcon />
              </Button>
            </Grid>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

Contact.propTypes = {
  contact: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    company: PropTypes.string,
    emailAddress: PropTypes.string.isRequired,
    type: PropTypes.oneOf(TYPES).isRequired,
    typeOtherName: PropTypes.string,
    sendWelcomeEmail: PropTypes.string,
  }).isRequired,
  showValidation: PropTypes.bool,
  showSendWelcomeOption: PropTypes.bool,
  hideSiteContacts: PropTypes.bool,
  welcomeEmailOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  disabled: PropTypes.bool,
  fixedType: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default Contact;
