import jss from 'jss';

const styles = {
  formControl: {
    width: '100%'
  },
  identifierContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  identifierLockButton: {
    marginLeft: 15
  },
  actionsContainer: {
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  progressContainer: {
    padding: '24px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box'
  }
};

export const { classes } = jss.createStyleSheet(styles).attach();
