import { createBlankContact } from "./components/Contact";

export function getNumericValue(rawValue) {
  if (typeof rawValue === "number") {
    return isNaN(rawValue) ? null : rawValue;
  }
  if (typeof rawValue !== "string") {
    return null;
  }
  const value = rawValue
    .trim()
    // Strip GST for total values
    .replace(/\s*\+\s*GST$/i, "")
    // Strip m² for quantity values
    .replace(/\s*m(?:2|²|\^2)$/i, "")
    // Strip dollar value
    .replace(/^\$/, "")
    .replace(/[^\d.-]/g, "")
    .trim();

  const numeric = +value.replace(/[\s,]+/g, "");
  return getNumericValue(numeric);
}

export function formatCurrency(value) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NZD",
  });

  const text = formatter.format(value);

  return text.replace("NZ", "").replace(".00", "");
}

export function doMergeContacts(incoming, contact, index) {
  let contacts = incoming;

  if (index === -1) {
    contacts.push(contact);
  } else {
    contacts[index] = contact;
  }

  try {
    if (
      (contact && contact.name && contact.name.length) ||
      (contact.phoneNumber && contact.phoneNumber.length) ||
      (contact.company && contact.company.length) ||
      (contact.emailAddress && contact.emailAddress.length)
    ) {
      if (index > 0) {
        // do we need a way to remove if
        const prev = contacts[index - 1];

        if (
          prev &&
          prev.type === "OTHER" &&
          !prev.name.length &&
          !prev.phoneNumber.length &&
          !prev.emailAddress.length &&
          !prev.company.length
        ) {
          // contacts.splice(index, 1);
        }
      }
    }

    // if the last one is none blank, create a new one
    // last one so create another one

    const blanks = contacts.filter(
      (c) => c.type === "OTHER" && isContactBlank(c)
    );

    if (blanks.length < 1) {
      const contact = createBlankContact("");
      contacts.push(contact);
    }
  } catch (err) {
    console.error(err);
  }

  return contacts;
}

export function isContactBlank(contact) {
  return (
    contact &&
    typeof contact == "object" &&
    contact.type === "OTHER" &&
    !(contact.name && contact.name.length) &&
    !(contact.emailAddress && contact.emailAddress.length) &&
    !(contact.phoneNumber && contact.phoneNumber.length)
  );
}
