import React from "react";
import PropTypes from "prop-types";
import { classes } from "./styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import HandoverContactsStep, {
  isValid as isContactsValid,
} from "../steps/contacts/index";
import HandoverDetailsStep, {
  isValid as isDetailsValid,
} from "../steps/details/index";
import HandoverDocumentsStep, {
  isValid as isDocumentsValid,
} from "../steps/documents/index";
import HandoverJobStep from "../steps/job/index";
import HandoverSiteStep, { isValid as isSiteValid } from "../steps/site/index";
import HandoverQuoteStep, {
  isValid as isQuoteValid,
} from "../steps/quote/index";
import Button from "@material-ui/core/Button";
import gql from "nanographql";
import {
  injectConfig,
  injectUser,
  graphql,
  getStaticConfig,
} from "@markham/react-services";
import { createBlankContact, isContactValid } from "../components/Contact";
import StyledButton from "../components/StyledButton";
import { COLOURS } from "../const";

const SAVE_HANDOVER = gql`
  mutation saveJobHandover($data: JobHandoverInformationInput!) {
    saved: saveJobHandover(data: $data)
  }
`;

const GET_AWS_DETAILS = gql`
  {
    credentials: getAWSCredentials {
      accessKeyId
      secretAccessKey
      sessionToken
      expiration
    }
    bucket: getAWSBucket
    region: getAWSBucketRegion
  }
`;

const STEP_COMPONENTS = {
  Details: HandoverDetailsStep,
  Site: HandoverSiteStep,
  Contacts: HandoverContactsStep,
  Documents: HandoverDocumentsStep,
  Quote: HandoverQuoteStep,
};

const STEP_VALIDATION = {
  Details: isDetailsValid,
  Site: isSiteValid,
  Contacts: isContactsValid,
  Documents: isDocumentsValid,
  Quote: isQuoteValid,
};

const STEPS = Object.keys(STEP_COMPONENTS);

class HandoverScreen extends React.Component {
  constructor(props) {
    super(props);

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setDirty = this.setDirty.bind(this);
    this.setDisabled = this.setDisabled.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onScrollToTop = this.onScrollToTop.bind(this);

    this.state = {
      disabled: false,
      dirty: false,
      activeStep: 0,
      showValidation: false,
      complete: false,
      completePM: false,
      handover: {
        internalContactName: null,
        identifier: "",
        projectName: "",
        projectDescription: "",
        site: {
          name: "",
          address1: "",
          address2: "",
          region: "",
          city: "",
          postcode: "",
        },
        locationCode: "",
        invoiceToCompany: "",
        orderNumber: "",
        contract: false,
        existingCustomer: false,
        contacts: [createBlankContact()],
        comments: "",
        position: null,
        expectedFirstVisitDate: null,
        expectedCompletionDate: null,
        attachments: [],
        estimatedSalesValue: "",
        emailInformation: false,
        submitted: false,
        countryCode: null,
        rebate: "",
        admix: "",
        admixCharge: "",
        quote: {
          identifier: "",
          groups: [],
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { countryCode, internalContactName, ...rest } = state.handover;

    let dirty = false;

    if (
      !countryCode ||
      props.config.available_countries.findIndex(
        ({ code }) => code === countryCode
      ) === -1
    ) {
      countryCode = props.config.country_code;
      dirty = true;
    }

    if (typeof internalContactName !== "string") {
      internalContactName = props.user.name || props.user.username || "";
    }

    if (!dirty) {
      return {};
    }

    return {
      handover: {
        ...rest,
        countryCode,
        internalContactName,
      },
    };
  }

  componentDidMount() {
    this.credentialsPromise = graphql(
      getStaticConfig().api.salesUrl,
      GET_AWS_DETAILS()
    );
    this.credentialsPromise.then(
      (credentialsResult) =>
        new Promise((resolve) =>
          this.setState(
            {
              credentialsResult,
            },
            resolve
          )
        )
    );
  }

  createActiveStepSetter(activeStep) {
    return () => {
      this.setState(
        {
          activeStep,
        },
        () => {
          this.isValid();
          this.onScrollToTop();
        }
      );
    };
  }

  handleFileChange(files, callback) {
    const handover = this.state.handover;

    if (handover) {
      handover.attachments = files;

      this.setState({ handover }, callback);
    } else {
      callback();
    }
  }

  async handleNext(e) {
    if (!this.isValid()) {
      this.setState({
        showValidation: true,
      });
    }

    this.setState(
      (state) => ({
        activeStep: state.activeStep + 1,
      }),
      () => {
        this.onScrollToTop();
      }
    );
  }

  handleBack() {
    this.setState(
      (state) => ({
        activeStep: state.activeStep - 1,
      }),
      this.onScrollToTop
    );
  }

  handleReset() {
    this.setState(
      {
        activeStep: 0,
      },
      this.onScrollToTop
    );
  }

  static getStepComponent(index) {
    return STEP_COMPONENTS[STEPS[index]] || null;
  }

  onChange(handover, callback) {
    this.setState(({ handover: oldValue }) => {
      const value =
        handover instanceof Function ? handover(oldValue) : handover;
      return {
        handover: {
          ...oldValue,
          ...value,
        },
        dirty: true,
      };
    }, callback);
  }

  isValid() {
    const invalidStep = STEPS.findIndex(
      (step) => !STEP_VALIDATION[step](this.state.handover, this.props)
    );
    return invalidStep === -1;
  }

  async handleComplete(event) {
    if (!this.isValid()) {
      return this.setState({
        showValidation: true,
      });
    }
    try {
      const success = await this.handleSave(event, {
        ...this.state.handover,
        submitted: true,
        completed: true,
      });
      if (success) {
        this.setState({
          completePM: true,
        });
      }
    } catch (e) {
      console.warn(e);
      alert("Failed to submit handover, please report this issue");
      this.setState({
        disabled: false,
        completePM: false,
      });
      // Let ErrorBoundary catch this
      throw e;
    }
  }

  async handleSave(event, handover) {
    if (handover.readOnly) {
      return false;
    }

    try {
      const { identifierConfirmed, ...rest } = handover;

      if (!identifierConfirmed) {
        alert("Please confirm the job number");
        return false;
      }

      await new Promise((resolve) => this.setDisabled(true, resolve));

      rest.contacts = rest.contacts.filter(
        (c) => c && c.name && isContactValid(c)
      );

      const body = SAVE_HANDOVER({ data: rest });
      const saved = await graphql(getStaticConfig().api.salesUrl, body);

      if (!saved) {
        alert(
          "We were unable to save the handover, the handover may have already been submitted"
        );
        return false;
      }
    } catch (e) {
      console.warn(e);
      alert(e);
      return false;
    } finally {
      await new Promise((resolve) => this.setDisabled(false, resolve));
    }

    return true;
  }

  setDirty(dirty, callback) {
    this.setState(
      {
        dirty,
      },
      callback
    );
  }

  setDisabled(disabled, callback) {
    this.setState(
      {
        disabled,
      },
      callback
    );
  }

  onScrollToTop() {
    const element = document.querySelector("#top-form");
    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  render() {
    const {
      showValidation: showValidationBase,
      handover,
      activeStep,
      complete,
      dirty,
      disabled,
      completePM,
    } = this.state;

    const showValidation =
      !handover.readOnly && (showValidationBase || handover.submitted);

    const StepComponent = this.state.handover.identifierConfirmed
      ? HandoverScreen.getStepComponent(activeStep)
      : HandoverJobStep;

    const isAdmin = this.props.roles.isOrChild(this.props.user.role, "admin");

    const stepComponent = (
      <div className={classes.stepComponent}>
        <StepComponent
          value={handover}
          onChange={this.onChange}
          setDisabled={this.setDisabled}
          setDirty={this.setDirty}
          onBack={this.handleBack}
          onNext={this.handleNext}
          user={this.props.user}
          handleFileChange={this.handleFileChange}
          disabled={handover.readOnly || disabled}
          showValidation={showValidation}
        />
      </div>
    );

    return (
      <React.Fragment>
        <span
          id="top-form"
          style={{ position: "absolute", top: 0, left: "50vw" }}
        />
        <div className={classes.container}>
          <Paper className="paper">
            {complete || completePM ? (
              <div className={classes.complete}>
                <Typography
                  variant="h2"
                  style={{
                    color: COLOURS.blue,
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  Thank you
                </Typography>
                <Typography style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  {completePM
                    ? `A job will now be available in MJM with the job number: ${handover.identifier}`
                    : "We have sent this information on to the PM team to process"}
                </Typography>
                <React.Fragment>
                  <br />
                  <StyledButton magenta>Create another</StyledButton>
                </React.Fragment>
              </div>
            ) : (
              <React.Fragment>
                {!handover.readOnly &&
                !(complete || completePM) &&
                handover.submitted ? (
                  <Typography className={classes.submittedWarning}>
                    Warning! You are modifying a handover that has already been
                    submitted!
                  </Typography>
                ) : null}

                <div className={classes.header}>
                  <div />
                  <Typography
                    component="h1"
                    variant="h4"
                    style={{ marginBottom: "24px", color: COLOURS.blue }}
                  >
                    Job Handover
                  </Typography>
                  <div className={classes.identifierHeader}>
                    {this.state.handover.identifierConfirmed ? (
                      <Typography className={classes.identifier}>
                        #{this.state.handover.identifier}
                      </Typography>
                    ) : null}
                  </div>
                </div>
                {this.state.handover.identifierConfirmed ? (
                  <React.Fragment>
                    <Stepper
                      activeStep={complete ? STEPS.length : activeStep}
                      className={classes.stepper}
                      style={{
                        marginBottom: "24px",
                        borderRadius: 4,
                        backgroundColor: "#f4f4f4",
                      }}
                      nonLinear
                    >
                      {STEPS.map((label, index) => {
                        let title = label;

                        if (title == "Contacts") {
                          title = "SWANKYS Contacts";
                        }

                        return (
                          <Step
                            key={label}
                            onClick={
                              disabled
                                ? null
                                : this.createActiveStepSetter(index)
                            }
                            className={disabled ? null : classes.step}
                          >
                            <StepLabel
                              error={
                                showValidation &&
                                !STEP_VALIDATION[label](handover, this.props)
                              }
                            >
                              {title}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {stepComponent}
                    <div className={classes.actionsContainer}>
                      <div className={classes.actionsRightContainer}>
                        {!handover.readOnly ? (
                          <StyledButton
                            onClick={
                              activeStep === STEPS.length - 1
                                ? this.handleComplete
                                : this.handleNext
                            }
                            magenta
                            className={classes.button}
                            disabled={!dirty || disabled}
                          >
                            {activeStep === STEPS.length - 1
                              ? "Add to MJM"
                              : "Next Step"}
                          </StyledButton>
                        ) : null}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  stepComponent
                )}
              </React.Fragment>
            )}
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

HandoverScreen.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
  roles: PropTypes.object,
};

export default injectConfig(injectUser(HandoverScreen));
