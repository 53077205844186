import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import { classes } from "./styles";
import { injectUser } from "@markham/react-services";
import ReactDOM from "react-dom";
import Map from "./map";
import Button from "@material-ui/core/Button";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { PRODUCT_LIST, WHAT_LIST } from "../../const";
import { createBlankContact, Contact } from "../../components/Contact";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import { doMergeContacts } from "../../helpers";

export function isValid(value) {
  return !!(
    value.site.address1 &&
    value.site.city &&
    value.contacts.filter((c) => c.type == "PROJECT_MANAGER").length > 0 &&
    value.contacts.filter((c) => c.type == "SITE").length > 0
  );
}

class HandoverSiteStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMap: false,
      welcomeEmailOptions: [],
      applicators: [],
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
    this.setAddressReference = this.setAddressReference.bind(this);
    this.onShowMap = this.onShowMap.bind(this);
    this.setMapReference = this.setMapReference.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.handleToggleProduct = this.handleToggleProduct.bind(this);
  }

  componentDidMount() {
    const { contacts } = this.props.value;

    const requiredContacts = ["SITE", "PROJECT_MANAGER"];

    requiredContacts.forEach((type) => {
      if (!contacts.find((c) => c.type === type)) {
        this.handleContactChange(-1, createBlankContact(type));
      }
    });

    fetch("https://admin-markhamglobal.herokuapp.com/api/getEmailTemplates", {
      headers: {
        "X-API-Key": "EKU2629YNNZ50AC7XAWXEMKHIVK17YPUTSJL2Z2P87EE1X8F",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          welcomeEmailOptions: data.templates,
        });
      });

    fetch("https://admin-markhamglobal.herokuapp.com/api/getGroups", {
      headers: {
        "X-API-Key": "EKU2629YNNZ50AC7XAWXEMKHIVK17YPUTSJL2Z2P87EE1X8F",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          applicators: data.data,
        });
      });
  }

  onFieldChange(event) {
    const {
      target: { name, value, type, checked },
    } = event;

    if (name == "applicatorEmail") {
      return this.props.onChange((record) => {
        applicatorEmail: value;
      });
    }

    if (name == "projectDescription") {
      return this.props.onChange((record) => {
        return { ...record, projectDescription: value };
      });
    }

    return this.props.onChange(({ site }) => ({
      site: {
        ...site,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  }

  onPlaceChanged(place) {
    const components = {};

    const componentMapping = {
      street_number: "short_name",
      route: "long_name",
      sublocality_level_1: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    };

    (place.address_components || []).forEach(function mapComponent(component) {
      const addressType = component.types[0];
      if (!componentMapping[addressType]) {
        return;
      }
      components[addressType] = component[componentMapping[addressType]];
    });

    const position = {
      latitude: place.geometry ? place.geometry.location.lat() : 0,
      longitude: place.geometry ? place.geometry.location.lng() : 0,
    };

    this.props.onChange(({ site }) => ({
      site: {
        ...site,
        address1: [components.street_number || "", components.route || ""]
          .join(" ")
          .trim(),
        address2: (components.sublocality_level_1 || "").trim(),
        city: (components.locality || "").trim(),
        region: (components.administrative_area_level_1 || "").trim(),
        postcode: (components.postal_code || "").trim(),
      },
      position,
    }));
  }

  setAddressReference(node) {
    if (!node) {
      return;
    }

    if (this.node === node) {
      return;
    }

    if (this.unregisterListen instanceof Function) {
      this.unregisterListen();
    }

    const input = ReactDOM.findDOMNode(node);

    const autocomplete = new window.google.maps.places.Autocomplete(input);

    if (this.props.value.position) {
      const circle = new window.google.maps.Circle({
        center: {
          lat: this.props.value.position.latitude,
          lng: this.props.value.position.longitude,
        },
        radius: 100,
      });
      autocomplete.setBounds(circle.getBounds());
    }

    const listener = () => this.onPlaceChanged(autocomplete.getPlace());

    autocomplete.addListener("place_changed", listener);

    this.unregisterListen = () => {
      window.google.maps.event.clearListeners(input, "focus");
      window.google.maps.event.clearListeners(input, "blur");
      window.google.maps.event.clearListeners(input, "keydown");
    };
  }

  onShowMap() {
    this.setState({
      showMap: true,
    });
  }

  setMapReference(map) {
    this.map = map;
  }

  onApplicatorChange(event) {
    this.props.onChange({
      applicatorEmail: event.target.value,
    });
  }

  onMapClick(event) {
    this.props.onChange({
      position: {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      },
    });
  }
  /**
   * Toggle a product enabled or disabled in the what the client wants section
   */
  handleToggleProduct(e, productName) {
    let products = this.props.value.products;

    if (e.target.checked) {
      products.push({
        name: productName,
      });
    } else {
      products = products.filter((product) => product.name != productName);
    }
    this.props.onChange({
      products,
    });
  }

  handleContactChange(index, contact) {
    const contacts = doMergeContacts(this.props.value.contacts, contact, index);

    return this.props.onChange({
      contacts,
    });
  }

  render() {
    const {
      value: {
        site: { address1, address2, region, city, postcode },
        position,
        projectDescription,
        products,
        isNewConstruction,
        isRetroFit,
        applicatorEmail,
        projectType,
        contacts,
      },
      disabled,
      showValidation,
    } = this.props;

    const showMap = this.state.showMap || !!this.props.value.position;

    let marker;

    if (
      showMap &&
      this.props.value.position &&
      this.props.value.position.latitude
    ) {
      marker = {
        position: {
          lat: this.props.value.position.latitude,
          lng: this.props.value.position.longitude,
        },
        defaultAnimation: 2,
      };
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <React.Fragment>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3">Site Address</Typography>

              <TextField
                required
                label="Site Address 1"
                fullWidth
                id="address1"
                name="address1"
                onChange={this.onFieldChange}
                value={address1}
                disabled={!!disabled}
                inputRef={this.setAddressReference}
                error={showValidation && !address1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address2"
                name="address2"
                label="Site Address 2"
                fullWidth
                disabled={!!disabled}
                value={address2}
                onChange={this.onFieldChange}
              />
            </Grid>
            <Grid item xs={12} className={classes.addressGroupLine}>
              <TextField
                required
                id="city"
                name="city"
                label="Site City"
                disabled={!!disabled}
                value={city}
                onChange={this.onFieldChange}
                error={showValidation && !city}
              />
              <TextField
                id="region"
                name="region"
                label="Site Region"
                disabled={!!disabled}
                value={region}
                onChange={this.onFieldChange}
              />
              <TextField
                id="postcode"
                name="postcode"
                label="Site Postcode"
                disabled={!!disabled}
                value={postcode}
                onChange={this.onFieldChange}
              />
            </Grid>
            <Grid item xs={12}>
              {showMap ? (
                <div className={classes.mapContainer}>
                  <Map
                    disabled={disabled}
                    marker={marker}
                    containerElement={
                      <div className={classes.mapInnerContainer} />
                    }
                    mapElement={<div className={classes.actualMap} />}
                    onMapClick={this.onMapClick}
                    onMapLoad={this.setMapReference}
                  />
                </div>
              ) : (position && position.latitude && position.longitude) ||
                !disabled ? (
                <Button onClick={this.onShowMap} variant="contained">
                  Show map
                </Button>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="projectDescription"
                name="projectDescription"
                label="Project Description"
                fullWidth
                disabled={!!disabled}
                value={projectDescription}
                multiline
                rowsMax="20"
                rows="5"
                onChange={this.onFieldChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">Applicator (s)</Typography>

              <Select
                value={applicatorEmail}
                fullWidth
                labelId="applicatorEmail"
                label="Applicator (s)"
                placeholder="Applicator (s)"
                onChange={this.onApplicatorChange.bind(this)}
                inputProps={{
                  name: "applicatorEmail",
                  id: "applicatorEmail",
                }}
                disabled={!!disabled}
              >
                <MenuItem key={-1} value={""}>
                  Unassigned
                </MenuItem>

                {this.state.applicators &&
                  this.state.applicators.map((applicator, i) => {
                    const { id, title } = applicator;
                    return (
                      <MenuItem key={i} value={title}>
                        {title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">What the client wants</Typography>
              <Select
                value={projectType}
                onChange={this.onChange}
                style={{ width: "100%" }}
                inputProps={{
                  name: "projectType",
                  id: "projectType",
                }}
              >
                {[
                  { name: "Floor-tect", code: "Floor-tect" },
                  { name: "Base-tect", code: "Base-tect" },
                  { name: "Civil-tect", code: "Civil-tect" },
                  { name: "Top-tect", code: "Top-tect" },
                  { name: "Slab-tect", code: "Slab-tect" },
                ].map(({ code, name }) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>

              <FormGroup row>
                {PRODUCT_LIST.map((product) => {
                  return (
                    <FormControlLabel
                      key={product}
                      style={{ width: "230px" }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            products &&
                            products.filter((p) => p.name == product).length > 0
                          }
                          onChange={(e) => this.handleToggleProduct(e, product)}
                          value={product}
                        />
                      }
                      label={product}
                    />
                  );
                })}
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">What are we providing</Typography>
              <InputLabel htmlFor="projectType" style={{ marginBottom: "8px" }}>
                Help the PM team and Applicators understand what you have sold
                your client
              </InputLabel>
              <FormGroup
                row
                style={{ marginBottom: "24px", marginTop: "16px" }}
              >
                {PRODUCT_LIST.map((product) => {
                  return (
                    <FormControlLabel
                      key={product}
                      style={{ width: "230px" }}
                      control={
                        <Checkbox
                          checked={
                            products &&
                            products.filter((p) => p.name == product).length > 0
                          }
                          onChange={(e) => this.handleToggleProduct(e, product)}
                          value={product}
                        />
                      }
                      label={product}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">
                WHEN and WHAT are we doing on this PROJECT
              </Typography>
              <InputLabel htmlFor="projectType" style={{ marginBottom: "8px" }}>
                Help the PM team and Applicators understand what you have sold
                your client
              </InputLabel>

              <FormGroup row>
                {WHAT_LIST.map((product) => {
                  return (
                    <FormControlLabel
                      key={product}
                      style={{ width: "230px" }}
                      control={
                        <Checkbox
                          checked={
                            products &&
                            products.filter((p) => p.name == product).length > 0
                          }
                          onChange={(e) => this.handleToggleProduct(e, product)}
                          value={product}
                        />
                      }
                      label={product}
                    />
                  );
                })}
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNewConstruction}
                    onChange={this.onChange}
                    value="1"
                    name="isNewConstruction"
                    color="primary"
                  />
                }
                label="New Construction"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRetroFit}
                    onChange={this.onChange}
                    value="1"
                    name="isRetroFit"
                    color="primary"
                  />
                }
                label="Retro fit"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">Contacts</Typography>

              {contacts.map((contact, i) => {
                if (
                  contact &&
                  (contact.type == "PROJECT_MANAGER" || contact.type == "SITE")
                ) {
                  return (
                    <Contact
                      key={i}
                      showValidation={showValidation}
                      contact={contact}
                      fixedType
                      welcomeEmailOptions={this.state.welcomeEmailOptions}
                      showSendWelcomeOption
                      onChange={(record) => {
                        this.handleContactChange(i, record);
                      }}
                      disabled={disabled}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </Grid>
        </React.Fragment>
      </MuiPickersUtilsProvider>
    );
  }
}

HandoverSiteStep.propTypes = {
  value: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  showValidation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default injectUser(HandoverSiteStep);
