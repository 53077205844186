import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { StyledDropzone } from "../../components/StyledDropzone";
import { Typography } from "@material-ui/core";

const REQUIRED_TYPES = ["PLAN", "CONFIRMED_PROPOSAL"];

function hasDocumentOfType(value, type) {
  return (
    value.attachments.findIndex(({ type: other }) => other === type) !== -1
  );
}

export function isValid(value) {
  const uploadingIndex = value.attachments.findIndex(
    ({ object, uploading, pending }) => !object || uploading || pending
  );
  if (uploadingIndex > -1) {
    return false;
  }
  const notProvided = value.attachments.findIndex(
    ({ type }) => !type || type === "NOT_PROVIDED"
  );
  if (notProvided > -1) {
    return false;
  }
  const requiredIndex = REQUIRED_TYPES.findIndex(
    (type) => !hasDocumentOfType(value, type)
  );
  return requiredIndex === -1;
}

class HandoverDocumentsStep extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography>
            Please provide the following required documents:
          </Typography>
          <ul>
            <li>
              <Typography>Markup</Typography>
            </li>
            <li>
              <Typography>Final &amp; Agreed Proposal</Typography>
            </li>
            <li>
              <Typography>Agreement (Contract or PO)</Typography>
            </li>
            <li>
              <Typography>SWMS</Typography>
            </li>
            <li>
              <Typography>SDS</Typography>
            </li>
          </ul>

          <StyledDropzone
            onChange={this.props.handleFileChange}
            handover={this.props.value}
            user={this.props.user}
            disabled={this.props.disabled}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

HandoverDocumentsStep.propTypes = {
  value: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleFileChange: PropTypes.func.isRequired,
};

export default HandoverDocumentsStep;
