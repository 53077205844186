import "./index.css";
import "./jss";
import "./static-config";
import React from "react";
import { render } from "react-dom";
import Screens from "./screens";
import { ParentComponent } from "@markham/react-services";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import * as Sentry from "@sentry/browser";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00B4DA",
    },
    secondary: {
      main: "#d61d7d",
    },
  },
  typography: {
    fontFamily: ["Campton", "-apple-system", "Calibri", "sans-serif"],
    h4: {
      fontWeight: 600,
    },
  },
  overrides: {
    Button: {
      text: {
        fontWeight: 600,
        color: "#fff",
      },
    },
  },
});

Sentry.init({
  dsn: "https://a4734dc2677341f5b5058c5cfe402cee@o80128.ingest.sentry.io/5751768",
});

class App extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <ParentComponent
          progressIndicator={<CircularProgress style={{ marginTop: "60px" }} />}
        >
          {() => <Screens />}
        </ParentComponent>
      </MuiThemeProvider>
    );
  }
}

render(<App />, document.getElementById("app"));
