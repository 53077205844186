import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import HandoverFindJobStep from "./find-job";
import HandoverLoadJobStep from "./load-job";

class HandoverJobStep extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path="/handover/:countryCode/:identifier"
          exact
          render={(props) => <HandoverLoadJobStep {...this.props} {...props} />}
        />
        <Route
          path="/handover"
          exact
          render={(props) => <HandoverFindJobStep {...this.props} {...props} />}
        />
        <Redirect to="/handover" />
      </Switch>
    );
  }
}

HandoverJobStep.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HandoverJobStep;
