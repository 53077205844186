import { Button } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const StyledButton = (props) => {
  const { style, magenta, ...rest } = props;

  let styles = {
    width: "100%",
    marginTop: "42px",
    color: "#fff",
    fontWeight: 600,
  };

  if (style) {
    styles = Object.assign({}, styles, style);
  }

  const color = magenta ? "secondary" : "primary";

  return (
    <Button variant="contained" style={styles} color={color} {...rest}>
      {props.children}
    </Button>
  );
};

StyledButton.propTypes = {
  style: PropTypes.object,
  magenta: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default StyledButton;
