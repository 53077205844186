import jss from "jss";

const styles = {
  appBar: {
    position: "relative",
  },
  appBarInner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  submittedWarning: {
    backgroundColor: "#ff4081",
    color: "#ffffff",
    padding: "16px",
    borderRadius: "4px",
    margin: "12px 0",
  },
  identifier: {
    fontFamily: "monospace",
    fontSize: "14px",
    display: "inline-block",
    padding: "7px",
    lineHeight: "16px",
    marginBottom: "12px",
    marginTop: "-16px",
    border: "1px solid #f4f4f4",
    borderRadius: "4px",
  },
};

export const { classes } = jss.createStyleSheet(styles).attach();
