import React from "react";
import PropTypes from "prop-types";
import Group, { createBlank, isValid as isGroupValid } from "./group";
import { classes } from "./styles";
import { formatCurrency, getNumericValue } from "../../helpers";
import Grid from "@material-ui/core/Grid/Grid";
import { injectUser } from "@markham/react-services";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { COLOURS } from "../../const";

export function isValid(value, props) {
  return true;
}

class HandoverQuoteStep extends React.Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
  }

  getGroups() {
    const groups = this.props.value.quote.groups;
    if (groups.length === 0) {
      return [createBlank()];
    }
    return groups;
  }

  onChange(original) {
    return (callback) => {
      return this.props.onChange(({ quote }) => {
        const groups = quote.groups.slice(),
          index = groups.indexOf(original);
        if (index === -1) {
          groups.push(callback(createBlank()));
        } else {
          groups[index] = callback(groups[index]);
        }
        return {
          quote: {
            ...quote,
            groups,
          },
        };
      });
    };
  }

  onRemove(original) {
    return () => {
      const groups = this.props.value.quote.groups.slice(),
        index = groups.indexOf(original);
      if (index !== -1) {
        groups.splice(index, 1);
      }
      return this.props.onChange(({ quote }) => ({
        quote: {
          ...quote,
          groups,
        },
      }));
    };
  }

  onFieldChange(event) {
    const { name, value } = event.target;

    if (name == "estimatedGrossProfit" || name == "travelDistanceReturn") {
      return this.props.onChange({
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : getNumericValue(event.target.value),
      });
    }

    return this.props.onChange(({ quote }) => ({
      quote: {
        ...quote,
        [name]: value,
      },
    }));
  }

  onAddGroup() {
    return this.props.onChange(({ quote }) => ({
      quote: {
        ...quote,
        groups: quote.groups.concat(createBlank()),
      },
    }));
  }

  render() {
    const {
      disabled,
      showValidation,
      value: { quote, estimatedGrossProfit, travelDistanceReturn },
    } = this.props;
    const groups = this.getGroups();

    const totalVisits = groups.reduce((total = 0, group) => {
      const v = parseInt(group.quotedVisits);

      if (!isNaN(v)) {
        return (total += v);
      } else {
        return total;
      }
    }, 0);

    const productInfoLines = [];

    groups.map((group, i) => {
      group.items.map((product) => {
        const { financialProductCode, quantity, costPerLitre } = product;

        if (costPerLitre > 0) {
          productInfoLines.push(
            `<strong>${financialProductCode}</strong> <strong>${quantity}m</strong> @ <strong>$${costPerLitre}/l</strong>`
          );
        }
      });
    });

    const actualTravelDistanceReturn = parseInt(travelDistanceReturn) || 0;

    const productCosts = groups
      .reduce((total, group) => {
        if (group.items.length > 0) {
          return (
            total +
            group.items.reduce((subtotal, product) => {
              const costPerLitre = getNumericValue(product.costPerLitre);
              const area = product.quantity;
              const coverage = product.coverage;

              if (costPerLitre && area && coverage) {
                return subtotal + (area / coverage).toFixed(2) * costPerLitre;
              } else {
                return subtotal;
              }
            }, 0)
          );
        } else {
          return total;
        }
      }, 0)
      .toFixed(2);

    const travelPrice = actualTravelDistanceReturn * (totalVisits + 1) * 0.5;
    const siteVisitCost = (totalVisits + 1) * 500;
    const totalCost =
      parseFloat(siteVisitCost) +
      parseFloat(travelPrice) +
      parseFloat(productCosts);

    const totalProjectSold = groups.reduce((total, group) => {
      if (group.items.length > 0) {
        return (
          total +
          group.items.reduce((subtotal, product) => {
            const value = getNumericValue(product.total);

            if (value) {
              return subtotal + value;
            } else {
              return subtotal;
            }
          }, 0)
        );
      } else {
        return total;
      }
    }, 0);

    const difference = totalProjectSold - totalCost;

    return (
      <React.Fragment>
        {groups.map((group, index) => (
          <div className={classes.group} key={index}>
            <br />
            <Typography variant="h6" style={{ color: COLOURS.blue }}>
              Value Confirmation
            </Typography>
            <br />
            <Group
              showValidation={showValidation}
              group={group}
              onChange={this.onChange(group)}
              disabled={disabled}
              onRemove={group.items.length > 1 ? null : this.onRemove(group)}
            />
            {!disabled && quote.groups.length > 1 ? (
              <Grid item className={classes.actionButtonItem} xs={12}>
                <Button
                  size="small"
                  color="secondary"
                  onClick={this.onRemove(group)}
                >
                  Remove
                </Button>
              </Grid>
            ) : null}
          </div>
        ))}
        {!disabled && quote.groups.length ? (
          <Grid container spacing={24} className={classes.item}>
            <Grid item className={classes.actionButtonItem} xs={12}>
              <Button size="small" onClick={this.onAddGroup}>
                Add
              </Button>
            </Grid>
          </Grid>
        ) : null}
        <Typography
          variant="h5"
          style={{
            marginTop: "44px",
            color: COLOURS.blue,
            marginBottom: "24px",
          }}
        >
          Pricing Inputs
        </Typography>

        <Grid item xs={12} style={{ marginTop: "12px" }}>
          <TextField
            id="travelDistanceReturn"
            name="travelDistanceReturn"
            label="Travel Distance (kms, return)"
            fullWidth
            disabled={disabled}
            value={travelDistanceReturn}
            onChange={this.onFieldChange.bind(this)}
          />
        </Grid>

        <Typography
          variant="h5"
          style={{
            marginTop: "44px",
            color: COLOURS.blue,
            marginBottom: "24px",
          }}
        >
          Pricing Confirmation
        </Typography>

        <Grid item xs={12}>
          <Typography variant="body1">
            LABOUR: Site visits required <strong>{totalVisits}</strong> +{" "}
            <strong>1 extra</strong> x <strong>$500 / day</strong> ={" "}
            <strong className={classes.highlight}>
              {formatCurrency(siteVisitCost)}
            </strong>
          </Typography>

          <Typography>
            PRODUCT COSTS:{" "}
            <span
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{ __html: productInfoLines.join(",") }}
            ></span>{" "}
            =
            <strong className={classes.highlight}>
              {formatCurrency(productCosts)}
            </strong>
          </Typography>

          <Typography>
            TRAVEL: <strong>{actualTravelDistanceReturn}km</strong> x{" "}
            <strong>{totalVisits + 1} visits x $0.50c / km</strong> ={" "}
            <strong className={classes.highlight}>
              {formatCurrency(travelPrice)}
            </strong>
          </Typography>

          <Typography>
            MISC COSTS: <strong className={classes.highlight}>$1000</strong>
          </Typography>
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            marginTop: "24px",
            borderTop: "1px dashed lightgrey",
            paddingTop: "24px",
          }}
        >
          <Grid item xs={6}>
            <TextField
              label="TOTAL PROJECT COST"
              fullWidth
              disabled
              value={formatCurrency(totalCost)}
              style={{ marginBottom: "8px" }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="TOTAL SECURED SALE"
              fullWidth
              disabled
              value={formatCurrency(totalProjectSold)}
              style={{ marginBottom: "8px" }}
            />
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={6}>
            <TextField
              label="TOTAL OPS Calculated GP LUMP for this project"
              fullWidth
              disabled
              value={formatCurrency(difference)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="ESTIMATED Gross Profit"
              fullWidth
              disabled
              value={
                estimatedGrossProfit ? estimatedGrossProfit : "Not provided"
              }
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

HandoverQuoteStep.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default injectUser(HandoverQuoteStep);
