import {
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import Dropzone from "react-dropzone";
import { TYPE_MAP } from "../const";
import { graphql, getStaticConfig } from "@markham/react-services";
import { GET_AWS_DETAILS } from "../queries";
import { S3 } from "aws-sdk";
import UUID from "uuid";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

/**
 * Styled form field dropdown
 *
 * @param {props} props
 * @returns
 */
export function StyledDropzone(props) {
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [s3Clients, setS3Clients] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [attachmentMenuIndex, setAttachmentMenuIndex] = useState(null);
  const [attachmentAnchorElement, setAttachmentAnchorElement] = useState(null);
  const [credentialsResult, setCredentialsResult] = useState({});

  useEffect(() => {
    const credentialsPromise = graphql(
      getStaticConfig().api.salesUrl,
      GET_AWS_DETAILS()
    );

    credentialsPromise.then(
      (result) =>
        new Promise((resolve) => {
          setCredentialsResult(result);
        })
    );
  }, []);

  const getS3Client = () => {
    if (
      !credentialsResult ||
      !credentialsResult.credentials ||
      !credentialsResult.credentials.accessKeyId
    ) {
      return null;
    }

    const {
      credentials: { accessKeyId, secretAccessKey, sessionToken },
      bucket,
      region,
    } = credentialsResult;

    const clientKey = `${accessKeyId}-${bucket}-${region}`;

    if (s3Clients[clientKey]) {
      return s3Clients[clientKey];
    } else {
      s3Clients[clientKey] = new S3({
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region,
        params: {
          Bucket: bucket,
        },
      });

      setS3Clients(s3Clients);
    }

    return s3Clients[clientKey];
  };

  const getUrl = ({ bucket, key }) => {
    if (!credentialsResult) {
      return "";
    }

    const client = getS3Client();

    if (!client) {
      return;
    }

    client.documentUrls = client.documentUrls || {};
    const cacheKey = `${bucket}:${key}`;

    if (client.documentUrls[cacheKey]) {
      return client.documentUrls[cacheKey];
    }

    client.documentUrls[cacheKey] = client.getSignedUrl("getObject", {
      Bucket: bucket,
      Key: key,
      Expires: 4 * 60 * 60, // 4 hours
    });

    return client.documentUrls[cacheKey];
  };

  const onDrop = (files) => {
    const newAttachments = files.map((file) => ({
      key: UUID.v4(),
      file,
      name: file.name,
      pending: true,
      type: "NOT_PROVIDED",
    }));

    props.onChange(attachments.concat(newAttachments), triggerUpload);
  };

  const triggerUpload = () => {
    const pendingFiles = props.handover.attachments
      .filter(({ object }) => !object)
      .filter(({ key }) => key && !uploadingFiles[key]);

    pendingFiles.forEach(({ key }) => (uploadingFiles[key] = true));

    Promise.allSettled(pendingFiles.map(upload)).catch(console.error);
  };

  const onRemove = (attachment) => {
    const attachments = props.handover.attachments.filter((a) =>
      attachment.key
        ? a.key !== attachment.key
        : a.object.key !== attachment.object.key
    );

    props.onChange(attachments);
  };

  const onOpenMenu = (index) => {
    return (event) => {
      setAttachmentAnchorElement(event.currentTarget);
      setAttachmentMenuIndex(index);
    };
  };

  const onCloseMenu = () => {
    setAttachmentAnchorElement(null);
    setAttachmentMenuIndex(null);
  };

  const onSelectType = (attachment, type) => {
    const files = attachments.slice();
    const index = files.findIndex(
      (other) =>
        other === attachment || (attachment.key && other.key === attachment.key)
    );

    if (index > -1) {
      files[index] = {
        ...files[index],
        type,
      };
    }

    props.onChange(files);
    onCloseMenu();
  };

  const upload = async (attachment) => {
    try {
      let updatedAttachment;
      const { bucket } = credentialsResult;

      const client = getS3Client();
      const fileKey = `${props.user._id}/${UUID.v4()}`;

      uploadingFiles[fileKey] = true;
      setUploadingFiles(uploadingFiles);

      const upload = new S3.ManagedUpload({
        params: {
          Bucket: bucket,
          Key: fileKey,
          ContentType: attachment.file.type,
          Body: attachment.file,
        },
        service: client,
      });

      await upload.promise();

      updatedAttachment = {
        object: {
          bucket,
          key: fileKey,
        },
      };

      const attachmentsCopy = props.handover.attachments.slice();

      const index = attachmentsCopy.findIndex(
        ({ key }) => attachment.key === key
      );

      if (index > -1) {
        attachmentsCopy[index] = {
          type: attachmentsCopy[index].type,
          typeOtherName: attachmentsCopy[index].typeOtherName,
          name: attachmentsCopy[index].name,
          file: updatedAttachment.object
            ? undefined
            : attachmentsCopy[index].file,
          key: fileKey,
          associatedToContact: attachmentsCopy[index].associatedToContact,
          pending: updatedAttachment.object ? undefined : true,
          uploading: updatedAttachment.object ? undefined : false,
          ...updatedAttachment,
        };
      }

      props.onChange(attachmentsCopy);
    } catch (uploadError) {
      console.warn({ uploadError });
      console.error("UPLOAD ERROR");
      updatedAttachment = {
        error: uploadError.message,
      };
    }

    const files = uploadingFiles.filter((f, k) => k !== fileKey);

    setUploadingFiles(files);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(dragging ? activeStyle : {}),
    }),
    [dragging]
  );

  const attachments = props.handover.attachments || [];

  return (
    <section className="container">
      <Dropzone
        onDrop={onDrop}
        onDragEnter={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={style}>
            <input {...getInputProps()} />
            <Typography>Drop files here to upload or..</Typography>

            <Button color="primary" variant="text">
              Select Files
            </Button>
          </div>
        )}
      </Dropzone>

      <table style={{ width: "100%" }}>
        <tbody>
          {attachments.map((attachment, index) => (
            <tr key={index}>
              <td>
                {
                  {
                    [attachment.pending || attachment.uploading]: (
                      <CircularProgress size={22} />
                    ),
                    [!attachment.uploading && !!attachment.error]: <div />,
                  }[true]
                }
              </td>
              <td>
                {attachment.object ? (
                  <a
                    href={getUrl(attachment.object)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography>{attachment.name}</Typography>
                  </a>
                ) : (
                  <Typography>{attachment.name}</Typography>
                )}
                {!attachment.uploading &&
                !attachment.object &&
                attachment.error ? (
                  <Typography style={{ color: "red" }}>
                    Error: {attachment.error}
                  </Typography>
                ) : undefined}
              </td>
              <td style={{ width: "240px" }}>
                <Button
                  disabled={props.disabled}
                  variant="contained"
                  aria-owns={
                    attachmentMenuIndex === index ? `type-menu-${index}` : null
                  }
                  aria-haspopup="true"
                  onClick={onOpenMenu(index)}
                  color={
                    props.showValidation &&
                    !(attachment.type && attachment.type !== "NOT_PROVIDED")
                      ? "secondary"
                      : "default"
                  }
                >
                  {attachment.type &&
                  attachment.type !== "NOT_PROVIDED" &&
                  TYPE_MAP[attachment.type]
                    ? `${TYPE_MAP[attachment.type]}`
                    : "Please select.."}
                </Button>
                <Menu
                  anchorEl={
                    attachmentMenuIndex === index
                      ? attachmentAnchorElement
                      : null
                  }
                  open={attachmentMenuIndex === index}
                  onClose={onCloseMenu}
                  id={`type-menu-${index}`}
                  disabled={props.disabled || !attachment.object}
                >
                  <MenuItem disabled value="Please select">
                    Please select
                  </MenuItem>
                  {Object.keys(TYPE_MAP).map((type) => (
                    <MenuItem
                      key={type}
                      onClick={() => onSelectType(attachment, type)}
                    >
                      {TYPE_MAP[type]}
                    </MenuItem>
                  ))}
                </Menu>
                {!props.disabled ? (
                  <Button
                    color="secondary"
                    onClick={() => onRemove(attachment)}
                  >
                    Remove
                  </Button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

StyledDropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  handover: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  user: PropTypes.object,
  showValidation: PropTypes.bool,
};
