import jss from "jss";

const styles = {
  required: {
    color: "red",
  },
  quoteDetails: {},
  group: {
    marginTop: "24px",
    borderTop: "1px dashed #d63f95",
    "& > $actionButtonItem": {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  item: {
    "& + $item": {
      marginTop: "24px",
      borderTop: "1px dashed lightgrey",
    },
    "&:first-child": {
      paddingTop: "12px",
    },
  },
  actionButtonItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tripleGroupLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& > *": {
      flex: 1,
      maxWidth: "calc(calc(100% / 3) - 3px)",
    },
  },
  highlight: {
    borderBottom: "1px dotted #999",
    backgroundColor: "#fffacc",
    padding: "4px",
  },
  doubleGroupLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& > *": {
      flex: 1,
      maxWidth: "calc(calc(100% / 2) - 3px)",
    },
  },
};

export const { classes } = jss.createStyleSheet(styles).attach();
