export const PRODUCT_LIST = [
  "FLOOR-TECT SYSTEM",
  "Curing A2000",
  "Flooring Moisture Control A2000",
  "Hygiene Protection MEDI+",
  "SLAB-TECT  SYSTEM",
  "Curing Assistance A1000",
  "Hardening",
  "Anti-dusting A1000",
  "Sheen Enhancement",
  "BASE-TECT SYSTEM",
  "TOP-TECT SYSTEM",
  "Waterproofing",
  "CIVIL-TECT SYSTEM",
  "INFRA-TECT SYSTEM",
  "Durability",
  "ASR",
  "Lower Cover",
  "Corrosion Protection",
  "Crack Healing",
];

export const WHAT_LIST = [
  "Burnished finish",
  "Broom finish",
  "Curing 0-24hrs",
  "Scrubbing required",
  "Retro 24hrs+",
  "Walls",
  "Soffits",
  "Work@Height",
  "Special Access",
  "Concrete Blockwork",
  "Precast",
];

export const TYPE_MAP = {
  PLAN: "Markup",
  CONFIRMED_PROPOSAL: "Final & Agreed Proposal",
  CONTRACT: "Agreement (Contract or PO)",
  SWMS: "SWMS",
  SDS: "SDS",
  OTHER: "Other",
};

export const LISTED_TYPES = {
  TAKEOFF: "Takeoff",
  CONFIRMED_PROPOSAL: "Proposal",
  AGREEMENT: "Agreement, (contract or PO)",
};

export function isValidDateString(value) {
  return (
    typeof value === "string" &&
    !isNaN(new Date(value).getTime()) &&
    // just make sure its a date with T
    /^\d{4}-\d{2}-\d{2}T.+/.test(value)
  );
}

export const COLOURS = {
  blue: "#00B4DA",
  magenta: "#d61d7d",
};
