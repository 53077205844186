import React from "react";
import { PropTypes } from "prop-types";
import Item, {
  createBlank as createBlankItem,
  isValid as isItemValid,
  isValidNumericValue,
} from "./item";
import Grid from "@material-ui/core/Grid/Grid";
import { classes } from "./styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField/TextField";

export function createBlank() {
  return {
    items: [],
    quotedVisits: "",
    additionalVisitRate: "$400",
    notes: "",
    variationRate: "",
  };
}

export function isValid(group, props) {
  if (!group.items.length) {
    return false;
  }

  const invalidItemIndex = group.items.findIndex(
    (item) => !isItemValid(item, props)
  );

  if (invalidItemIndex !== -1) {
    return false;
  }

  return (
    typeof group.notes === "string" &&
    isValidNumericValue(group.quotedVisits) &&
    isValidNumericValue(group.additionalVisitRate)
  );
}

class QuoteGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addItem: null,
    };
  }

  getItems() {
    const items = this.props.group.items;
    if (items.length === 0) {
      return [createBlankItem()];
    }
    return items;
  }

  onChange(original) {
    return (callback) => {
      return this.props.onChange((group) => {
        const items = group.items.slice(),
          index = items.indexOf(original);
        if (index === -1) {
          items.push(callback(createBlankItem()));
        } else {
          items[index] = callback(items[index]);
        }
        return {
          ...group,
          items,
        };
      });
    };
  }

  onRemove(original) {
    return () => {
      const items = this.props.group.items.slice(),
        index = items.indexOf(original);
      if (index !== -1) {
        items.splice(index, 1);
      }
      return this.props.onChange((group) => ({
        ...group,
        items,
      }));
    };
  }

  onAddItem() {
    this.props.onChange((group) => ({
      ...group,
      items: group.items.concat(createBlankItem()),
    }));
  }

  onFieldChange(event) {
    const { name, value } = event.target;
    return this.props.onChange((oldGroup) => ({
      ...createBlank(),
      ...oldGroup,
      [name]: value,
    }));
  }

  render() {
    const {
      group,
      group: { quotedVisits, additionalVisitRate, notes },
      showValidation,
      disabled,
    } = this.props;
    const items = this.getItems();
    return (
      <React.Fragment>
        {items.map((item, index) => (
          <Grid container spacing={24} className={classes.item} key={index}>
            <Item
              showValidation={showValidation}
              item={item}
              onChange={this.onChange(item)}
              disabled={disabled}
              onRemove={group.items.length > 1 ? null : this.onRemove(item)}
            />
            {!disabled && group.items.length > 1 ? (
              <Grid item className={classes.actionButtonItem} xs={12}>
                <Button
                  size="small"
                  color="secondary"
                  onClick={this.onRemove(item)}
                >
                  Remove product
                </Button>
              </Grid>
            ) : null}
          </Grid>
        ))}
        {!disabled && group.items.length ? (
          <Grid container spacing={24} className={classes.item}>
            <Grid item className={classes.actionButtonItem} xs={12}>
              <Button size="small" onClick={this.onAddItem.bind(this)}>
                Add product
              </Button>
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.tripleGroupLine}>
            <TextField
              id="quotedVisits"
              name="quotedVisits"
              label="Site Visits Included"
              disabled={!!disabled}
              value={quotedVisits}
              onChange={this.onFieldChange.bind(this)}
              error={showValidation && !isValidNumericValue(quotedVisits)}
            />
            <TextField
              id="additionalVisitRate"
              name="additionalVisitRate"
              label="Additional Visit Charge"
              disabled={!!disabled}
              value={additionalVisitRate}
              onChange={this.onFieldChange.bind(this)}
              error={
                showValidation && !isValidNumericValue(additionalVisitRate)
              }
            />
          </Grid>
        </Grid>
        {notes ? (
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <TextField
                id="notes"
                name="notes"
                label="Notes"
                disabled
                value={notes}
                onChange={this.onFieldChange.bind(this)}
                fullWidth
                multiline
                rowsMax="4"
              />
            </Grid>
          </Grid>
        ) : undefined}
      </React.Fragment>
    );
  }
}

QuoteGroup.propTypes = {
  group: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default QuoteGroup;
