import React from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { classes } from "./styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HandoverScreen from "./HandoverScreen";
import SalesScreen from "./SalesScreen";

class Screens extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="absolute"
          color="default"
          className={classes.appBar}
          elevation={0}
        >
          <Toolbar className={classes.appBarInner}>
            <img
              style={{ margin: "12px 0", maxWidth: "180px" }}
              src="/images/markham-global-logo.png"
              alt="Logo"
            />
            <Typography component={Link} to="/logout">
              Logout
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="inner">
          <Switch>
            <Route path="/handover" component={HandoverScreen} />
            <Route path="/sales" component={SalesScreen} />
            <Redirect to="/sales" />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

export default Screens;
