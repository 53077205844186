import gql from "nanographql";

export const SAVE_HANDOVER = gql`
  mutation saveJobHandover($data: JobHandoverInformationInput!) {
    saved: saveJobHandover(data: $data)
  }
`;

export const GET_AWS_DETAILS = gql`
  {
    credentials: getAWSCredentials {
      accessKeyId
      secretAccessKey
      sessionToken
      expiration
    }
    bucket: getAWSBucket
    region: getAWSBucketRegion
  }
`;

export const GET_HANDOVER = gql`
  query getJobHandover($identifier: String!, $countryCode: String!) {
    handover: getJobHandover(
      identifier: $identifier
      countryCode: $countryCode
    ) {
      internalContactName
      identifier
      projectName
      projectDescription
      site {
        name
        address1
        address2
        city
        region
        postcode
      }
      locationCode
      invoiceToCompany
      orderNumber
      contract
      existingCustomer
      rebate
      admix
      admixCharge
      contacts {
        key
        name
        phoneNumber
        emailAddress
        type
        typeOtherName
        info
      }
      comments
      position {
        latitude
        longitude
      }
      expectedFirstVisitDate
      expectedCompletionDate
      attachments {
        associatedToContact
        object {
          bucket
          key
        }
        type
        typeOtherName
        name
      }
      estimatedSalesValue
      emailInformation
      submitted
      countryCode
      handoverType
      projectType
      travelDistanceReturn
      applicatorEmail
      isRetroFit
      isNewConstruction
      estimatedGrossProfit
      estimatedSalesBreakdown
      products {
        name
      }
      quote {
        identifier
        groups {
          items {
            financialProductCode
            financialProductBarcode
            description
            info
            quantity
            rate
            total
            lumpSum
          }
          quotedVisits
          additionalVisitRate
          notes
          variationRate
        }
      }
      completed
    }
    resubmit: canResubmitHandover
  }
`;
