import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import { classes } from "./styles";
import { injectUser } from "@markham/react-services";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { formatCurrency, getNumericValue } from "../../helpers";

export function createBlank() {
  return {
    financialProductCode: "",
    financialProductBarcode: "",
    description: "",
    info: "",
    quantity: "",
    rate: "",
    total: "",
    lumpSum: false,
  };
}

export function isValidNumericValue(total) {
  return typeof getNumericValue(total) === "number";
}

function getRate(item) {
  const { quantity, total } = item;
  const numericTotal = getNumericValue(total),
    numericQuantity = getNumericValue(quantity);
  if (
    !(isValidNumericValue(numericQuantity) && isValidNumericValue(numericTotal))
  ) {
    return "";
  }
  const numericRate = numericTotal / numericQuantity;
  if (isNaN(numericRate) || !isFinite(numericRate)) {
    return "";
  }
  const rounded = (Math.round(numericRate * 100) / 100).toString();
  const splitDecimal = rounded.split(".");
  return `${splitDecimal[0]}.${`${splitDecimal[1] || ""}00`.substr(0, 2)}`;
}

export function isValid(item, { roles, user }) {
  const rate = getRate(item);
  const baseValid = !!(
    isValidNumericValue(item.quantity) &&
    isValidNumericValue(item.total) &&
    typeof rate === "string" &&
    rate.length
  );

  if (!baseValid || !roles.isOrChild(user.role, "admin")) {
    return baseValid;
  }
  return !!(item.financialProductCode && item.financialProductBarcode);
}

const QuoteItem = (props) => {
  const onFieldChange = (event) => {
    const { name, value, checked, type } = event.target;
    return props.onChange((oldItem) => {
      const item = {
        ...createBlank(),
        ...oldItem,
        [name]: type === "checkbox" ? checked : value,
      };
      item.rate = getNumericValue(getRate(item));
      item.rate = typeof item.rate === "number" ? item.rate.toString() : "";
      item.quantity = getNumericValue(item.quantity).toString();

      return item;
    });
  };

  const value = getRate(props.item);
  const rate = typeof value === "string" && value.length ? `$${value}+GST` : "";

  const {
    showValidation,
    disabled,
    item: {
      quantity,
      total,
      financialProductCode,
      coverage,
      lumpSum,
      costPerLitre,
    },
    roles,
    user,
  } = props;
  const isAdmin = roles.isOrChild(user.role, "admin");

  const litres = coverage && quantity ? (quantity / coverage).toFixed(2) : "";
  const totalCost = formatCurrency(
    litres && costPerLitre ? litres * costPerLitre : "0"
  );

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        className={isAdmin ? classes.tripleGroupLine : classes.doubleGroupLine}
      >
        {isAdmin ? (
          <React.Fragment>
            <TextField
              id="financialProductCode"
              name="financialProductCode"
              label="Product required for project"
              disabled={!!disabled}
              value={financialProductCode}
              onChange={onFieldChange}
              error={showValidation && !financialProductCode}
              helperText="Product code"
            />
          </React.Fragment>
        ) : null}
        <FormControlLabel
          control={
            <Checkbox
              checked={!!lumpSum}
              name="lumpSum"
              onChange={onFieldChange}
              value="lumpSum"
              disabled={disabled}
            />
          }
          label="Lump Sum"
        />
      </Grid>

      <Grid item xs={12} className={classes.tripleGroupLine}>
        <TextField
          id="quantity"
          name="quantity"
          label="Area (m²)"
          disabled={!!disabled}
          value={quantity}
          onChange={onFieldChange}
          error={showValidation && !isValidNumericValue(quantity)}
        />
        <TextField
          id="rate"
          name="rate"
          label="Rate per m²"
          readOnly
          disabled
          value={rate}
        />

        <TextField
          id="total"
          name="total"
          label="Total Price"
          disabled={!!disabled}
          value={total}
          onChange={onFieldChange}
          error={showValidation && !isValidNumericValue(total)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="coverage"
          name="coverage"
          label="Coverage"
          disabled={!!disabled}
          value={coverage}
          onChange={onFieldChange}
          error={showValidation && !isValidNumericValue(coverage)}
        />
        <TextField
          id="costPerLitre"
          name="costPerLitre"
          label="Cost per litre"
          disabled={!!disabled}
          value={costPerLitre}
          onChange={onFieldChange}
          error={showValidation && !isValidNumericValue(costPerLitre)}
        />
        <TextField
          id="litresNeeded"
          name="litresNeeded"
          label="Litres needed"
          readOnly
          disabled
          value={litres}
        />

        <TextField
          id="totalCost"
          name="totalCost"
          label="Total Cost"
          disabled
          readOnly
          value={("$", totalCost.toLocaleString(2))}
        />
      </Grid>
    </React.Fragment>
  );
};

QuoteItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string.isRequired,
    quantity: PropTypes.numeric,
    total: PropTypes.string,
    coverage: PropTypes.string,
    costPerLitre: PropTypes.string,
    financialProductCode: PropTypes.string,
    financialProductBarcode: PropTypes.string,
    lumpSum: PropTypes.string,
  }).isRequired,
  roles: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
};

export default injectUser(QuoteItem);
