import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import { injectConfig } from "@markham/react-services";
import { Typography } from "@material-ui/core";
import { useEffect } from "react";

export function isValid(value) {
  return !!(
    value.internalContactName &&
    value.projectName &&
    value.invoiceToCompany
  );
}

class HandoverDetailsStep extends React.Component {
  onFieldChange(event) {
    this.props.onChange({
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  }

  render() {
    const {
      value: {
        internalContactName,
        projectName,
        invoiceToCompany,
        orderNumber,
        comments,
        projectType,
      },
      disabled,
      showValidation,
    } = this.props;

    let updatedProjectName = projectName;

    if (projectType && projectName) {
      if (projectName.toLowerCase().indexOf(projectType.toLowerCase()) === -1) {
        updatedProjectName = projectType.toUpperCase() + " " + projectName;
      }
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <React.Fragment>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <TextField
                required
                id="internalContactName"
                name="internalContactName"
                label="Markham Rep/Contact"
                fullWidth
                disabled={!!disabled}
                value={internalContactName}
                onChange={this.onFieldChange.bind(this)}
                error={showValidation && !internalContactName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="projectName"
                name="projectName"
                label="Project Name"
                helperText="SYSTEM Project Name region/state (m2-SV-sitevisits in quote)” e.g SLAB-TECH Warehouse NAPIER (1800SV5)"
                fullWidth
                disabled={!!disabled}
                value={updatedProjectName}
                onChange={this.onFieldChange.bind(this)}
                error={showValidation && !updatedProjectName}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">Invoicing</Typography>

              <TextField
                required
                id="invoiceToCompany"
                name="invoiceToCompany"
                label="Client"
                fullWidth
                disabled={!!disabled}
                value={invoiceToCompany || ""}
                onChange={this.onFieldChange.bind(this)}
                error={showValidation && !invoiceToCompany}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="orderNumber"
                name="orderNumber"
                label="Order Number"
                fullWidth
                disabled={!!disabled}
                value={orderNumber || ""}
                onChange={this.onFieldChange.bind(this)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">Comments</Typography>

              <TextField
                id="comments"
                name="comments"
                helperText="Any info Operations need to know/end use of the project/condition of the concrete/approx. m"
                fullWidth
                disabled={!!disabled}
                value={comments}
                onChange={this.onFieldChange.bind(this)}
                multiline
                rows="4"
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </MuiPickersUtilsProvider>
    );
  }
}

HandoverDetailsStep.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default injectConfig(HandoverDetailsStep);
