import { setStaticConfig } from '@markham/react-services';

export default setStaticConfig({
  envConfig: {
    url: {
      'sales.markham.cloud': 'production',
      'dev-sales.markham.cloud': 'development',
      'localhost': 'local',
      'default': 'local'
    }
  },
  env: {
    production: {
      storage: {
        prefix: 'markham.cloud'
      },
      api: {
        salesUrl: 'https://production.sales.api.markham.cloud',
        jobManagerUrl: 'https://production.api.markham.cloud',
      },
      auth: {
        domain: 'markham.au.auth0.com',
        clientID: 'WbMD13d510s5NTrNQg9tW1N1cHsOIaGE',
        audience: 'https://production.sales.api.markham.cloud/',
        redirectUri: 'https://sales.markham.cloud/callback',
        responseType: 'token id_token',
        scope: 'openid profile email'
      },
      mapBox: {
        token: 'pk.eyJ1IjoibWFya2hhbWdsb2JhbCIsImEiOiJjanMzdDBwOHYyaXhsM3lucnEybTl4Y2ZpIn0.bRVtTJF0TWvWHF5QJh5eAQ'
      },
      google: {
        apiKey: 'AIzaSyAID0xmv2uv8yL5uqzptoTnlpJJVWJdXI0'
      },
      bugsnag: {
        apiKey: '11117dd2a1214497c643ae338a147bbf'
      }
    },
    development: {
      storage: {
        prefix: 'dev.markham.cloud'
      },
      api: {
        salesUrl: 'https://development.sales.api.markham.cloud',
        jobManagerUrl: 'https://development.api.markham.cloud',
      },
      auth: {
        domain: 'markham-development.au.auth0.com',
        clientID: '2tuYIYc3bZAUURYITQjKzgFCj1ketewS',
        audience: 'https://development.sales.api.markham.cloud/',
        redirectUri: 'https://dev-sales.markham.cloud/callback',
        responseType: 'token id_token',
        scope: 'openid profile email'
      },
      mapBox: {
        token: 'pk.eyJ1IjoibWFya2hhbWdsb2JhbCIsImEiOiJjanMzdDBwOHYyaXhsM3lucnEybTl4Y2ZpIn0.bRVtTJF0TWvWHF5QJh5eAQ'
      },
      google: {
        apiKey: 'AIzaSyAID0xmv2uv8yL5uqzptoTnlpJJVWJdXI0'
      },
      bugsnag: {
        apiKey: '11117dd2a1214497c643ae338a147bbf'
      }
    },
    local: {
      storage: {
        prefix: 'local.markham.cloud'
      },
      api: {
        salesUrl: 'https://development.sales.api.markham.cloud',
        jobManagerUrl: 'https://development.api.markham.cloud',
      },
      auth: {
        domain: 'markham-development.au.auth0.com',
        clientID: '2tuYIYc3bZAUURYITQjKzgFCj1ketewS',
        audience: 'https://development.sales.api.markham.cloud/',
        redirectUri: 'http://localhost:3000/callback',
        responseType: 'token id_token',
        scope: 'openid profile email'
      },
      mapBox: {
        token: 'pk.eyJ1IjoibWFya2hhbWdsb2JhbCIsImEiOiJjanMzdDBwOHYyaXhsM3lucnEybTl4Y2ZpIn0.bRVtTJF0TWvWHF5QJh5eAQ'
      },
      google: {
        apiKey: 'AIzaSyAID0xmv2uv8yL5uqzptoTnlpJJVWJdXI0'
      },
      bugsnag: {
        apiKey: '11117dd2a1214497c643ae338a147bbf'
      }
    }
  }
});
