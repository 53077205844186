import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { classes } from "./styles";
import Button from "@material-ui/core/Button";
import {
  getStaticConfig,
  graphql,
  injectConfig,
} from "@markham/react-services";
import { GET_HANDOVER } from "../../queries";
import StyledButton from "../../components/StyledButton";

class HandoverFindJobStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirming: false,
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onConfirmIdentifier = this.onConfirmIdentifier.bind(this);
    this.onChangeIdentifier = this.onChangeIdentifier.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onContinue = this.onContinue.bind(this);
  }

  onFieldChange(event) {
    this.props.onChange({
      [event.target.name]: event.target.value,
    });
  }

  async onConfirmIdentifier() {
    await new Promise((resolve) => this.props.setDisabled(true, resolve));

    await new Promise((resolve) =>
      this.setState(
        {
          confirming: true,
        },
        resolve
      )
    );

    const body = GET_HANDOVER({
      identifier: this.props.value.identifier,
      countryCode: this.props.value.countryCode,
    });

    const { handover, resubmit } = await graphql(
      getStaticConfig().api.salesUrl,
      body
    );

    if (handover && handover.completed) {
      alert(
        "Job number has already been completed and sent to MJM, it can not be viewed or modified"
      );
      await new Promise((resolve) =>
        this.props.onChange(
          {
            identifier: "",
            identifierConfirmed: false,
          },
          resolve
        )
      );
    } else if (handover && handover.submitted && !resubmit) {
      alert("Job number has already been submitted for handover");
      await new Promise((resolve) =>
        this.props.onChange(
          {
            identifier: "",
            identifierConfirmed: false,
          },
          resolve
        )
      );
    } else if (handover) {
      this.props.onChange({
        ...handover,
        identifierConfirmed: true,
      });
    } else {
      // Didn't exist
      this.props.onChange({
        identifierConfirmed: true,
      });
    }

    // Reset current state

    await new Promise((resolve) => this.props.setDisabled(false, resolve));

    await new Promise((resolve) =>
      this.setState(
        {
          confirming: false,
        },
        resolve
      )
    );
  }

  onChangeIdentifier() {
    this.props.onChange({
      identifierConfirmed: false,
    });
  }

  onReset(event) {
    this.onContinue(event, this.props.value);
  }

  onContinue(event, existing) {
    this.setState(
      {
        existing: null,
      },
      () =>
        this.props.onChange({
          ...existing,
          identifierConfirmed: true,
        })
    );
  }

  render() {
    const { confirming, existing } = this.state;
    const {
      disabled,
      value: { countryCode, identifier, identifierConfirmed },
      config: { available_countries },
    } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={24}>
          {available_countries.length > 1 ? (
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="countryCode">Country</InputLabel>
                <Select
                  value={countryCode}
                  onChange={this.onFieldChange}
                  inputProps={{
                    name: "countryCode",
                    id: "countryCode",
                  }}
                  disabled={!!existing || disabled || identifierConfirmed}
                >
                  {available_countries.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12} className={classes.identifierContainer}>
            <TextField
              required
              id="identifier"
              name="identifier"
              label="Job # from Prospect list"
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  identifierConfirmed
                    ? this.onChangeIdentifier()
                    : this.onConfirmIdentifier();
                }
              }}
              disabled={!!existing || disabled || identifierConfirmed}
              value={identifier}
              onChange={this.onFieldChange}
            />
            <StyledButton
              className={classes.identifierLockButton}
              disabled={
                !!existing ||
                disabled ||
                (identifierConfirmed ? false : !(identifier && countryCode))
              }
              style={{ width: "100px", marginTop: 0 }}
              onClick={
                identifierConfirmed
                  ? this.onChangeIdentifier
                  : this.onConfirmIdentifier
              }
            >
              {identifierConfirmed ? "Modify" : "Confirm"}
            </StyledButton>
          </Grid>
          {confirming ? (
            <Grid item xs={12}>
              <Typography>
                Checking to see if there is an existing handover for this job
                number, please wait...
              </Typography>
            </Grid>
          ) : null}
          {existing ? (
            <Grid item xs={12}>
              <Typography>
                Any existing handover was found for the provided job number, you
                can either continue from the previously saved state, or start
                again
                <br />
                <br />
                If a handover was saved but not submitted it probably means work
                was done on it, so it is advised to continue with the current
                version
                <br />
                <br />
                <strong>
                  Please note that if you have done work on this handover and
                  have not saved your changed, please select Start from scratch,
                  this will use the values that are currently in memory
                </strong>
              </Typography>
              <div className={classes.actionsContainer}>
                <Button color="secondary" onClick={this.onReset}>
                  Start from scratch
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.onContinue}
                >
                  Continue with previously saved
                </Button>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </React.Fragment>
    );
  }
}

HandoverFindJobStep.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setDisabled: PropTypes.func,
  disabled: PropTypes.bool,
  config: PropTypes.object,
};

export default injectConfig(HandoverFindJobStep);
