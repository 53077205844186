import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography/Typography";
import Contact, { createBlankContact } from "../../components/Contact";
import { doMergeContacts } from "../../helpers";

export function isValid(value) {
  return true;
}

class HandoverContactsStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      welcomeEmailOptions: [],
    };

    this.handleAddContact = this.handleAddContact.bind(this);
  }

  componentDidMount() {
    fetch("https://admin-markhamglobal.herokuapp.com/api/getEmailTemplates", {
      headers: {
        "X-API-Key": "EKU2629YNNZ50AC7XAWXEMKHIVK17YPUTSJL2Z2P87EE1X8F",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          welcomeEmailOptions: data.templates,
        });
      });
  }

  handleContactChange(index, contact) {
    const contacts = doMergeContacts(this.props.value.contacts, contact, index);

    return this.props.onChange({
      contacts,
    });
  }

  handleAddContact() {
    const contacts = this.props.value.contacts;

    return this.props.onChange({
      contacts,
    });
  }

  render() {
    const {
      disabled,
      showValidation,
      value: { contacts },
    } = this.props;

    const displayedContacts = contacts.filter((contact) => {
      if (!contact || !contact.type) {
        return false;
      }

      if (contact.type == "PROJECT_MANAGER") {
        return false;
      }

      if (contact.type == "SITE") {
        return false;
      }

      return true;
    });

    if (!displayedContacts.length) {
      contacts.push(createBlankContact("ARCHITECT"));
      contacts.push(createBlankContact("ENGINEER"));
      contacts.push(createBlankContact("BUILDER"));
    }

    return (
      <React.Fragment>
        <Typography variant="h3">Contacts</Typography>

        {contacts.map((contact, i) => {
          if (!contact) {
            return null;
          }

          if (contact.type == "PROJECT_MANAGER") {
            return null;
          }

          if (contact.type == "SITE") {
            return null;
          }

          return (
            <Contact
              key={i}
              showValidation={showValidation}
              contact={contact}
              showSendWelcomeOption
              hideSiteContacts
              welcomeEmailOptions={this.state.welcomeEmailOptions}
              onChange={(record) => {
                this.handleContactChange(i, record);
              }}
              onDelete={() => {
                this.handleContactChange(i);
              }}
              disabled={disabled}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

HandoverContactsStep.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default HandoverContactsStep;
