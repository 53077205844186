import jss from 'jss';

const styles = {
  addressGroupLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      flex: 1,
      maxWidth: 'calc(calc(100% / 3) - 3px)'
    }
  },
  contactHeader: {
    marginTop: '24px',
    paddingTop: '24px',
    borderTop: '1px dashed lightgrey'
  },
  mapContainer: {

  },
  mapInnerContainer: {
    height: '100%',
    minHeight: '30vh',
    width: '100%'
  },
  actualMap: {
    height: '100%',
    minHeight: '30vh',
    width: '100%'
  }
};

export const { classes } = jss.createStyleSheet(styles).attach();
