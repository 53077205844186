import React from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import PropTypes from "prop-types";

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialPosition: {
        latitude: -39.5052721,
        longitude: 176.8767779,
      },
    };

    this.lastDoubleClick = Date.now();
    this.lastClick = Date.now();
    this.onDblClick = this.onDblClick.bind(this);
    this.onClick = this.onClick.bind(this);
    this.setMapReference = this.setMapReference.bind(this);
  }

  componentDidMount() {
    this.getCurrentLocation();
  }

  getCurrentLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      // Max age of 30 minutes
      maximumAge: 30 * 60 * 1000,
    };
    let shouldSetState = true;
    const callback = (position) => {
      if (!shouldSetState) {
        return;
      }
      this.setState({ initialPosition: position.coords });
    };
    navigator.geolocation.getCurrentPosition(callback, () => null, options);
    this.unregister = () => {
      shouldSetState = false;
    };
  }

  onDblClick() {
    this.lastDoubleClick = Date.now();
  }

  onClick(...args) {
    if (!this.props.onMapClick || this.props.disabled) {
      return;
    }

    const thisLastClick = Date.now();

    this.lastClick = thisLastClick;

    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }

    this.clickTimeout = setTimeout(() => {
      if (
        thisLastClick !== this.lastClick ||
        Math.abs(this.lastClick - this.lastDoubleClick) < 300
      ) {
        return;
      }

      this.props.onMapClick(...args);
    }, 250);
  }

  componentWillUnmount() {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }
    if (this.unregister) {
      this.unregister();
      this.unregister = null;
    }
  }

  getPosition() {
    if (this.props.marker && this.props.marker.position) {
      const latLng = this.props.marker.position;
      return {
        lat: latLng.lat instanceof Function ? latLng.lat() : latLng.lat,
        lng: latLng.lng instanceof Function ? latLng.lng() : latLng.lng,
      };
    }
    return {
      lat: this.state.initialPosition.latitude,
      lng: this.state.initialPosition.longitude,
    };
  }

  setMapReference(map) {
    if (this.props.onMapLoad instanceof Function) {
      this.props.onMapLoad(map);
    }
    if (!map) {
      return;
    }
    if (this.map === map) {
      return;
    }
    this.map = map;

    map.panTo(this.getPosition());
  }

  render() {
    let marker = "";

    if (this.props.marker) {
      marker = <Marker {...this.props.marker} />;
    }

    return (
      <GoogleMap
        ref={this.setMapReference}
        className={`google-map ${this.props.className || ""}`}
        onClick={this.onClick}
        onDblClick={this.onDblClick}
        defaultZoom={13}
        defaultCenter={this.getPosition()}
      >
        {marker}
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  marker: PropTypes.object,
  onMapLoad: PropTypes.func,
  onMapClick: PropTypes.func,
};

export default withGoogleMap(Map);
