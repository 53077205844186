import React from "react";
import PropTypes from "prop-types";
import { classes } from "./styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  injectConfig,
  injectUser,
  graphql,
  getStaticConfig,
} from "@markham/react-services";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Contact, createBlankContact } from "../components/Contact";
import { StyledDropzone } from "../components/StyledDropzone";
import { GET_HANDOVER, SAVE_HANDOVER } from "../queries";
import { COLOURS, isValidDateString, PRODUCT_LIST, WHAT_LIST } from "../const";
import StyledButton from "../components/StyledButton";
import { doMergeContacts } from "../helpers";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";

const requiredContacts = ["SITE", "ENGINEER", "BUILDER", "ARCHITECT"];

class SalesScreen extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleToggleProduct = this.handleToggleProduct.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleFetchJobInformation = this.handleFetchJobInformation.bind(this);
    this.onScrollToTop = this.onScrollToTop.bind(this);
    this.onChangeSalesBreakdown = this.onChangeSalesBreakdown.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDateFieldChange = this.onDateFieldChange.bind(this);

    this.state = {
      disabled: true,
      dirty: false,
      showValidation: false,
      complete: false,
      completePM: false,
      lookingUpHistory: false,
      lookedUpHistory: false,
      estimatedSalesBreakdownRows: [],
      estimatedSalesBreakdownText: "",
      handover: {
        internalContactName: null,
        isRetroFit: false,
        isNewConstruction: false,
        identifier: "",
        projectName: "",
        handoverType: "full",
        projectType: "",
        products: [],
        estimatedGrossProfit: "",
        estimatedSalesBreakdown: "",
        projectDescription: "",
        site: {
          name: "",
          address1: "",
          address2: "",
          region: "",
          city: "",
          postcode: "",
        },
        contract: false,
        existingCustomer: false,
        contacts: [],
        comments: "",
        position: null,
        expectedFirstVisitDate: null,
        expectedCompletionDate: null,
        attachments: [],
        estimatedSalesValue: "",
        emailInformation: false,
        submitted: false,
        countryCode: null,
        rebate: "",
        admix: "",
        admixCharge: "",
        quote: {
          identifier: "",
          groups: [],
        },
      },
    };
  }

  componentDidMount() {
    requiredContacts.forEach((type) => {
      const contacts = this.state.handover.contacts;

      if (!contacts.find((c) => c.type === type)) {
        contacts.push(createBlankContact(type));
      }

      let clean = [];

      contacts.find((c, i) => {
        // remove any others which are not last {
        if (i < contacts.length - 1 && c.type == "OTHER" && !c.name.length) {
          // continue
        } else {
          clean.push(c);
        }
      });

      this.setState({
        handover: {
          ...this.state.handover,
          contacts: clean,
        },
      });
    });
  }

  static getDerivedStateFromProps(props, state) {
    let { countryCode, internalContactName, ...rest } = state.handover;

    let dirty = false;

    if (
      !countryCode ||
      props.config.available_countries.findIndex(
        ({ code }) => code === countryCode
      ) === -1
    ) {
      countryCode = props.config.country_code;
      dirty = true;
    }

    if (
      typeof internalContactName !== "string" ||
      internalContactName.length < 1
    ) {
      internalContactName = props.user.name || props.user.username || "";
    }

    if (!dirty) {
      return {};
    }

    return {
      handover: {
        ...rest,
        countryCode,
        internalContactName,
      },
    };
  }

  onChange(event) {
    const update = this.state.handover;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (event.target.name === "estimatedSalesBreakdownText") {
      this.setState({
        estimatedSalesBreakdownText: value,
      });

      return;
    }

    update[event.target.name] = value;

    this.setState({
      update,
    });
  }

  onDateFieldChange(value) {
    const update = this.state.handover;
    update["expectedFirstVisitDate"] =
      value instanceof Date ? value.toISOString() : value;

    this.setState({
      update,
    });
  }

  onChangeSalesBreakdown(index, event) {
    const { estimatedSalesBreakdownRows } = this.state;

    estimatedSalesBreakdownRows[index][event.target.name] = event.target.value;
    console.log(index, estimatedSalesBreakdownRows.length, event.target.name);

    if (
      index >= estimatedSalesBreakdownRows.length - 1 &&
      event.target.value &&
      event.target.value.length
    ) {
      if (event.target.name === "productName") {
        estimatedSalesBreakdownRows.push({
          productName: "",
          productRate: "",
          productUsage: "",
        });
      }
    } else {
      // if this whole rolw is
    }

    this.setState({
      estimatedSalesBreakdownRows,
    });
  }

  /**
   * Toggle a product enabled or disabled in the what the client wants section
   */
  handleToggleProduct(e, productName) {
    if (e.target.checked) {
      let products = this.state.handover.products;

      if (!products) {
        products = [];
      }

      products.push({
        name: productName,
      });

      this.setState({
        ...this.state,
        handover: {
          ...this.state.handover,
          products,
        },
      });
    } else {
      const products = this.state.handover.products.filter(
        (product) => product.name != productName
      );

      this.setState({
        ...this.state,
        handover: {
          ...this.state.handover,
          products,
        },
      });
    }
  }

  isValid() {
    if (!this.state.handover.projectName.length) {
      return false;
    }

    if (!this.state.handover.identifier.length) {
      return false;
    }

    if (!this.state.handover.estimatedGrossProfit) {
      return false;
    }

    return true;
  }

  async handleFetchJobInformation() {
    this.setState({
      lookingUpHistory: true,
    });

    const body = GET_HANDOVER({
      identifier: this.state.handover.identifier,
      countryCode: this.state.handover.countryCode,
    });

    const { handover } = await graphql(getStaticConfig().api.salesUrl, body);

    if (handover && (handover.completed || handover.submitted)) {
      alert(
        "Job number has already been completed and sent to MJM, it can not be viewed or modified"
      );

      this.setState({
        lookingUpHistory: false,
        disabled: true,
        estimatedSalesBreakdownRows: [],
        estimatedSalesBreakdownText: "",
      });
    } else {
      if (handover) {
        const { estimatedSalesBreakdown } = handover;

        let estimatedSalesBreakdownDataRows = [];
        let estimatedSalesBreakdownText = "";

        if (estimatedSalesBreakdown) {
          const jsonData = JSON.parse(estimatedSalesBreakdown);

          if (jsonData) {
            if (jsonData.notes) {
              estimatedSalesBreakdownText = jsonData.notes;
            }

            if (jsonData.rows) {
              estimatedSalesBreakdownDataRows = jsonData.rows;
            }
          }
        }

        if (
          estimatedSalesBreakdownDataRows.length === 0 ||
          estimatedSalesBreakdownDataRows[0].productName !== ""
        ) {
          estimatedSalesBreakdownDataRows.push({
            productName: "",
            productRate: "",
            productUsage: "",
          });
        }

        console.log(estimatedSalesBreakdownDataRows);

        handover.handoverType = "full";

        this.setState({
          lookingUpHistory: false,
          lookedUpHistory: true,
          disabled: false,
          handover: handover,
          estimatedSalesBreakdownRows: estimatedSalesBreakdownDataRows,
          estimatedSalesBreakdownText: estimatedSalesBreakdownText,
        });
      } else {
        this.setState({
          lookingUpHistory: false,
          lookedUpHistory: true,
          disabled: false,
          estimatedSalesBreakdownText: "",
          estimatedSalesBreakdownRows: [
            {
              productName: "",
              productRate: "",
              productUsage: "",
            },
          ],
        });
      }
    }
  }

  async handleSubmit() {
    try {
      const success = await this.handleSave({
        ...this.state.handover,
      });

      if (success) {
        this.setState({
          complete: true,
        });
      } else {
        throw new Error("handleSave returned false");
      }
    } catch (e) {
      console.warn(e);
      alert("Failed to submit handover, please report this issue");

      this.setState({
        disabled: false,
        complete: false,
      });
    }
  }

  async handleSave(handover) {
    if (handover.readOnly) {
      return false;
    }

    try {
      await new Promise((resolve) => this.setDisabled(true, resolve));

      handover.estimatedSalesBreakdown = JSON.stringify({
        rows: handover.estimatedSalesBreakdownRows,
        notes: handover.estimatedSalesBreakdownText,
      });

      const body = SAVE_HANDOVER({ data: handover });
      const saved = await graphql(getStaticConfig().api.salesUrl, body);

      if (!saved) {
        return false;
      }
    } catch (e) {
      console.warn(e);

      return false;
    } finally {
      await new Promise((resolve) => this.setDisabled(false, resolve));
    }

    return true;
  }

  setDisabled(disabled, callback) {
    this.setState(
      {
        disabled,
      },
      callback
    );
  }

  /**
   * Handle changing a contact details. This will automatically create or remove
   * blank rows as required.
   *
   * @param {int} index
   * @param {object} contact
   */
  handleContactChange(index, contact) {
    const contacts = doMergeContacts(
      this.state.handover.contacts,
      contact,
      index
    );

    this.setState({ ...this.state, contacts });
  }

  onScrollToTop() {
    const element = document.querySelector("#top-form");
    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  handleFileChange(files, callback) {
    const handover = this.state.handover;
    handover.attachments = files;

    this.setState({ handover }, callback);
  }

  render() {
    const {
      showValidation: showValidationBase,
      handover,
      complete,
      disabled,
      estimatedSalesBreakdownRows,
      estimatedSalesBreakdownText,
    } = this.state;

    const { expectedFirstVisitDate, rebate, admix, admixCharge } = handover;

    const showValidation =
      !handover.readOnly && (showValidationBase || handover.submitted);

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <span
          id="top-form"
          style={{ position: "absolute", top: 0, left: "50vw" }}
        />
        <div className={classes.container}>
          <Paper className="paper">
            {complete ? (
              <div>
                <Typography
                  variant="h3"
                  gutterBottom
                  style={{ textAlign: "center", fontWeight: 600 }}
                >
                  Thank you
                </Typography>
                <Typography style={{ textAlign: "center" }}>
                  We have received your sales handover and it has been emailed
                  to au.orders@markhamglobal.com for AU or
                  handovers@markhamglobal.com for NZ
                </Typography>

                <React.Fragment>
                  <br />
                  <StyledButton
                    magenta
                    onClick={() => (window.location.href = "/sales")}
                  >
                    Create another
                  </StyledButton>
                </React.Fragment>
              </div>
            ) : (
              <React.Fragment>
                <div className={classes.header}>
                  <div />
                  <Typography
                    component="h1"
                    variant="h4"
                    style={{ marginBottom: 24, color: COLOURS.blue }}
                  >
                    Sales Handover
                  </Typography>

                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="countryCode">Country</InputLabel>
                        <Select
                          value={this.state.handover.countryCode}
                          onChange={this.onChange}
                          disabled={
                            this.state.lookedUpHistory ||
                            this.state.lookingUpHistory
                          }
                          style={{ width: "100%" }}
                          inputProps={{
                            name: "countryCode",
                            id: "countryCode",
                          }}
                        >
                          {[
                            { name: "New Zealand", code: "NZL" },
                            { name: "Australia", code: "AUS" },
                          ].map(({ code, name }) => (
                            <MenuItem key={code} value={code}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id="identifier"
                        name="identifier"
                        label="Project Number"
                        helperText="(Year-PIPEDRIVE e.g 22-1234)"
                        fullWidth
                        disabled={
                          this.state.lookedUpHistory ||
                          this.state.lookingUpHistory
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            this.handleFetchJobInformation();
                          }
                        }}
                        value={this.state.handover.identifier}
                        onChange={this.onChange}
                      />
                    </Grid>

                    {!this.state.lookedUpHistory ? (
                      <Grid item xs={12}>
                        <StyledButton
                          type="submit"
                          disabled={
                            this.state.lookingUpHistory ||
                            !this.state.handover.identifier
                          }
                          onClick={this.handleFetchJobInformation}
                        >
                          Next
                        </StyledButton>
                      </Grid>
                    ) : null}

                    {this.state.lookingUpHistory ? (
                      <Grid item xs={12}>
                        <CircularProgress
                          color="primary"
                          style={{ margin: "0 auto" }}
                        />
                      </Grid>
                    ) : null}

                    {this.state.lookedUpHistory ? (
                      <div style={{ padding: "12px" }}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="projectName"
                            name="projectName"
                            label="Project Name"
                            helperText="Project Name-region/state-(m2-SV-sitevisits in quote)” e.g MARKHAM Warehouse NAPIER (1800SV5)"
                            fullWidth
                            disabled={!!disabled}
                            value={this.state.handover.projectName}
                            onChange={this.onChange}
                            error={showValidation && !projectName}
                          />
                        </Grid>

                        <Grid item xs={12} style={{ paddingTop: 12 }}>
                          <TextField
                            required
                            id="invoiceToCompany"
                            name="invoiceToCompany"
                            label="Client"
                            fullWidth
                            disabled={!!disabled}
                            value={this.state.handover.invoiceToCompany}
                            onChange={this.onChange}
                            error={showValidation && !invoiceToCompany}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="h3">
                            What are we providing
                          </Typography>
                          <InputLabel
                            htmlFor="projectType"
                            style={{ marginBottom: "8px" }}
                          >
                            Help the PM team and Applicators understand what you
                            have sold your client
                          </InputLabel>

                          <FormGroup
                            row
                            style={{ marginBottom: "24px", marginTop: "16px" }}
                          >
                            {PRODUCT_LIST.map((product) => {
                              return (
                                <FormControlLabel
                                  key={product}
                                  style={
                                    product.toLowerCase().indexOf("system") > -1
                                      ? { width: "100%" }
                                      : { width: "230px" }
                                  }
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.handover.products &&
                                        this.state.handover.products.filter(
                                          (p) => p.name == product
                                        ).length > 0
                                      }
                                      onChange={(e) =>
                                        this.handleToggleProduct(e, product)
                                      }
                                      value={product}
                                    />
                                  }
                                  label={product}
                                />
                              );
                            })}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h3">
                            WHEN and WHAT are we doing on this PROJECT
                          </Typography>
                          <InputLabel
                            htmlFor="projectType"
                            style={{ marginBottom: "8px" }}
                          >
                            Help the PM team and Applicators understand what you
                            have sold your client
                          </InputLabel>

                          <FormGroup row>
                            {WHAT_LIST.map((product) => {
                              return (
                                <FormControlLabel
                                  key={product}
                                  style={{ width: "230px" }}
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.handover.products &&
                                        this.state.handover.products.filter(
                                          (p) => p.name == product
                                        ).length > 0
                                      }
                                      onChange={(e) =>
                                        this.handleToggleProduct(e, product)
                                      }
                                      value={product}
                                    />
                                  }
                                  label={product}
                                />
                              );
                            })}
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="h3">Price breakdown</Typography>

                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingBottom: "16px",
                            }}
                          >
                            <TextField
                              required
                              id="estimatedGrossProfit"
                              name="estimatedGrossProfit"
                              label="Estimated Gross Profit"
                              fullWidth
                              disabled={!!disabled}
                              value={this.state.handover.estimatedGrossProfit}
                              onChange={this.onChange}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingBottom: "16px",
                            }}
                          >
                            <TextField
                              required
                              id="estimatedSalesValue"
                              name="estimatedSalesValue"
                              label="Total Sales Value"
                              fullWidth
                              disabled={!!disabled}
                              value={this.state.handover.estimatedSalesValue}
                              onChange={this.onChange}
                            />
                          </Grid>

                          <Typography
                            style={{ marginTop: "8px", marginBottom: "12px" }}
                          >
                            <strong>
                              The total sales value is made up of:
                            </strong>
                          </Typography>

                          {estimatedSalesBreakdownRows &&
                            estimatedSalesBreakdownRows.map((row, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                <Grid item xs={12} md={4}>
                                  <TextField
                                    name="productName"
                                    label="Product name"
                                    fullWidth
                                    disabled={!!disabled}
                                    value={row.productName}
                                    onChange={(e) =>
                                      this.onChangeSalesBreakdown(index, e)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <TextField
                                    name="productUsage"
                                    label="Usage (m2, m3, m)"
                                    fullWidth
                                    disabled={!!disabled}
                                    value={row.productUsage}
                                    onChange={(e) =>
                                      this.onChangeSalesBreakdown(index, e)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <TextField
                                    name="productRate"
                                    label="Rate"
                                    fullWidth
                                    disabled={!!disabled}
                                    value={row.productRate}
                                    onChange={(e) =>
                                      this.onChangeSalesBreakdown(index, e)
                                    }
                                  />
                                </Grid>
                              </div>
                            ))}
                          <TextField
                            name="estimatedSalesBreakdownText"
                            label="Other – Please specify"
                            multiline
                            fullWidth
                            rowsMax={3}
                            rows={3}
                            disabled={!!disabled}
                            value={estimatedSalesBreakdownText}
                            onChange={this.onChange}
                          />
                        </Grid>

                        <Grid item xs={12} style={{ paddingBottom: "8px" }}>
                          <Typography variant="h3">ADMIX</Typography>
                          <Select
                            value={admix}
                            style={{ marginTop: "16px", width: "98%" }}
                            name="admix"
                            label="Choose one option"
                            onChange={this.onChange}
                            disabled={!!disabled}
                          >
                            <MenuItem value="Charge via Builder">
                              Charge via Builder by m3 via MARKHAM Team dosing
                            </MenuItem>
                            <MenuItem value="Charge via READYMIX Company via ORDER">
                              Charge via READYMIX Company via ORDER
                            </MenuItem>
                            <MenuItem value="Charge via READYMIX from consignment TOTE">
                              Charge via READYMIX from consignment TOTE
                            </MenuItem>
                          </Select>
                        </Grid>

                        {admix !== "Charge via Builder" && (
                          <Grid item xs={12}>
                            <TextField
                              name="admixCharge"
                              label="ADMIX Charge (if not Charge via Builder)"
                              fullWidth
                              disabled={!!disabled}
                              value={admixCharge}
                              onChange={this.onChange}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <TextField
                            name="rebate"
                            label="Any rebate margin to be paid to READYMIX "
                            multiline
                            fullWidth
                            rowsMax={3}
                            rows={3}
                            disabled={!!disabled}
                            value={rebate}
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h3">Project details</Typography>
                          <Grid item xs={12}>
                            <DatePicker
                              autoOk
                              keyboard
                              clearable
                              minDate={new Date()}
                              maxDate={
                                isValidDateString(expectedFirstVisitDate)
                                  ? new Date(expectedFirstVisitDate)
                                  : null
                              }
                              format="dd/MM/yyyy"
                              placeholder="DD/MM/YYYY"
                              required
                              id="expectedFirstVisitDate"
                              name="expectedFirstVisitDate"
                              label="Expected date for First Application"
                              fullWidth
                              disableOpenOnEnter
                              mask={(value) =>
                                value
                                  ? [
                                      /\d/,
                                      /\d/,
                                      "/",
                                      /\d/,
                                      /\d/,
                                      "/",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]
                                  : []
                              }
                              disabled={!!disabled}
                              value={expectedFirstVisitDate}
                              onChange={this.onDateFieldChange}
                              error={
                                showValidation &&
                                !isValidDateString(expectedFirstVisitDate)
                              }
                            />
                          </Grid>

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.handover.isNewConstruction}
                                onChange={this.onChange}
                                value="1"
                                name="isNewConstruction"
                                color="primary"
                              />
                            }
                            label="New Construction"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.handover.isRetroFit}
                                onChange={this.onChange}
                                value="1"
                                name="isRetroFit"
                                color="primary"
                              />
                            }
                            label="Retro fit"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography>
                            <strong>
                              Any info Operations need to know/end use of the
                              project/condition of the concrete/approx. m
                            </strong>
                          </Typography>
                          <TextField
                            id="projectDescription"
                            name="projectDescription"
                            fullWidth
                            disabled={!!disabled}
                            value={this.state.handover.projectDescription}
                            onChange={this.onChange}
                            multiline
                            rowsMax="20"
                            rows="5"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="h3">Contacts</Typography>

                          {this.state.handover.contacts.map((contact, i) => {
                            return (
                              <Contact
                                key={i}
                                showValidation={showValidation}
                                contact={contact}
                                onChange={(record) => {
                                  this.handleContactChange(i, record);
                                }}
                                disabled={disabled}
                              />
                            );
                          })}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="h3"
                            style={{ paddingTop: "12px", marginBottom: "20px" }}
                          >
                            Attachments
                          </Typography>

                          <Typography>
                            Please provide the following documents:
                          </Typography>
                          <ul>
                            <li>
                              <Typography>Markup</Typography>
                            </li>
                            <li>
                              <Typography>
                                Final &amp; Agreed Proposal
                              </Typography>
                            </li>
                            <li>
                              <Typography>
                                Agreement (Contract or PO)
                              </Typography>
                            </li>
                          </ul>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            required
                            id="comments"
                            name="comments"
                            label="Any Comments?"
                            fullWidth
                            disabled={!!disabled}
                            value={this.state.handover.comments}
                            onChange={this.onChange}
                          />
                        </Grid>

                        <React.Fragment>
                          <Grid item xs={12}>
                            <StyledDropzone
                              onChange={this.handleFileChange}
                              handover={this.state.handover}
                              user={this.props.user}
                              disabled={disabled}
                            />
                          </Grid>
                        </React.Fragment>

                        <StyledButton
                          onClick={this.handleSubmit}
                          className={classes.button}
                          magenta
                          disabled={!this.isValid()}
                        >
                          Add to secured
                        </StyledButton>
                      </div>
                    ) : null}
                  </Grid>
                </div>
              </React.Fragment>
            )}
          </Paper>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

SalesScreen.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
};

export default injectConfig(injectUser(SalesScreen));
